export const playerManagement = {
  playerManagement: {
    edit: {
      title: "Edit Player",
      nickname: "Nickname",
      gender: "Gender",
      currency: "Currency",
      block_status: "Block Status",
      language: "Language",
      locale: "Locale",
    },
    columns: {
      tenant: "Tenant",
      username: "Username",
      block_status: "Block Status",
      created_at: "Created At",
      currency: "Currency",
      game_id: "Game ID",
      gender: "Gender",
      language: "Language",
      lobby_id: "Lobby ID",
      locale: "Locale",
      nickname: "Nickname",
      session_id: "Session ID",
      table_id: "Table ID",
      user_id: "User ID",
    },
    filters: {
      playerId: "Player ID",
      playerId_placeholder: "Enter Player ID",
      playerName: "Player Name",
      playerName_placeholder: "Enter Player Name",
      lobbyId: "Lobby ID",
      lobbyId_placeholder: "Enter Lobby ID",
      nickname: "Nickname",
      nickname_placeholder: "Enter Nickname",
      currency: "Currency",
      currency_placeholder: "Enter Currency",
    },
  },
};
