import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TenantControllerApiFactory } from "@/apis/tenant-management";
import InfinityTable from "@/components/Infinitytable";
import { useApi } from "@/hooks/useApi";
import { Card, Divider, Modal, message } from "@atom/ui-kit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { columns } from "./TenantManagement.columns";
import TenantManagementFilterSpace from "./TenantManagement.filters";
var DEFAULT_PAGE_SIZE = 50;
var TenantManagement = function () {
    var _a;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _b = useApi({
        asyncFunction: TenantControllerApiFactory().tenantsGet,
    }), callTenantManagementList = _b.call, tenantManagementData = _b.data, tenantManagementLoading = _b.loading;
    var _c = useApi({
        asyncFunction: TenantControllerApiFactory().tenantsNameDelete,
        successCallback: function (response) {
            message.success(t("root.delete_success"));
            callTenantManagementList();
        },
        errorCallback: function (error) {
            message.error(t("root.delete_error"));
        },
    }), callDeleteTenantManagement = _c.call, deleteTenantManagementLoading = _c.loading;
    var handleDelete = function (name) {
        Modal.confirm({
            title: t("root.delete_confirmation_title"),
            content: t("root.delete_confirmation_message"),
            okText: t("root.confirm"),
            okType: "danger",
            cancelText: t("root.cancel"),
            onOk: function () {
                callDeleteTenantManagement({
                    name: name,
                });
            },
        });
    };
    var handleAdd = function () {
        navigate("/tenant-management/add");
    };
    var handleEdit = function (key) {
        navigate("/tenant-management/".concat(key));
    };
    useEffect(function () {
        callTenantManagementList();
    }, []);
    return (_jsxs(Card, { style: { padding: "20px 0", height: "80vh" }, children: [_jsx(TenantManagementFilterSpace, { onAddNewData: handleAdd, onSearch: function () { return callDeleteTenantManagement(); } }), _jsx(Divider, {}), _jsx(InfinityTable, { columns: columns(handleEdit, handleDelete, t), dataSource: ((_a = tenantManagementData === null || tenantManagementData === void 0 ? void 0 : tenantManagementData.data) === null || _a === void 0 ? void 0 : _a.tenants) || [], loading: tenantManagementLoading || deleteTenantManagementLoading })] }));
};
export default TenantManagement;
