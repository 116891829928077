"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserControllerApiFactory } from "@/apis/user-management";
import SidebarBackground from "@/assets/images/sideback.png";
import useApi from "@/hooks/useApi";
import useZoomLevel from "@/hooks/useZoomLevel";
import { getUser } from "@/utils/storage";
import { Content, Layout, Sidebar } from "@atom/ui-kit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { getMenu } from "./AppLayout.utils";
import "./assets/sprite-flags-24x24.css";
import Logo from "./assets/wiselogo.svg";
import LogoCollapsed from "./assets/wiselogo_collapsed.svg";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/Appheader";
import BreadCrumb from "./components/BreadCrumb";
export var AppLayout = function () {
    var _a = useState(false), collapsed = _a[0], setCollapsed = _a[1];
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var lang = localStorage.getItem("language") || "en";
    var navigate = useNavigate();
    var user = getUser();
    useZoomLevel();
    var menu = useMemo(function () { return getMenu({ roles: "Admin" }, t, lang); }, [t, lang]);
    // get user session
    var call = useApi({
        asyncFunction: UserControllerApiFactory().backofficeusersByTenantUsernameGet,
        errorCallback: function (error) {
            navigate("/sign-in");
        },
    }).call;
    useEffect(function () {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [i18n]);
    useEffect(function () {
        call({
            username: user.username,
            tenant: "wisegaming",
        });
    }, []);
    return (_jsxs(Layout, { children: [_jsx(Sidebar, { sidebarBackground: "url(".concat(SidebarBackground, ")"), collapsed: collapsed, items: menu, logo: _jsx("img", { width: collapsed ? 45 : 150, src: collapsed ? LogoCollapsed : Logo, alt: "Logo" }), onCollapse: setCollapsed, footer: _jsx(AppFooter, {}) }), _jsxs(Layout, { children: [_jsx(AppHeader, { collapsed: collapsed, setCollapsed: setCollapsed, lang: lang, menu: menu }), _jsxs(Content, { style: { padding: 20, height: "calc(100% - 50px)" }, children: [_jsx(BreadCrumb, {}), _jsx(Outlet, {})] })] })] }));
};
export default AppLayout;
