export const playerManagement = {
  playerManagement: {
    edit: {
      title: "Oyuncuyu Düzenle",
      nickname: "Takma Ad",
      gender: "Cinsiyet",
      currency: "Para Birimi",
      block_status: "Engel Durumu",
      language: "Dil",
      locale: "Yerel Ayar",
    },
    columns: {
      tenant: "Tenant",
      username: "Kullanıcı Adı",
      block_status: "Engel Durumu",
      created_at: "Oluşturulma Tarihi",
      currency: "Para Birimi",
      game_id: "Oyun Kimliği",
      gender: "Cinsiyet",
      language: "Dil",
      lobby_id: "Lobi Kimliği",
      locale: "Yerel Ayar",
      nickname: "Takma Ad",
      session_id: "Oturum Kimliği",
      table_id: "Masa Kimliği",
      user_id: "Kullanıcı Kimliği",
    },
    filters: {
      playerId: "Oyuncu Kimliği",
      playerId_placeholder: "Oyuncu Kimliğini Girin",
      playerName: "Oyuncu Adı",
      playerName_placeholder: "Oyuncu Adını Girin",
      lobbyId: "Lobi Kimliği",
      lobbyId_placeholder: "Lobi Kimliğini Girin",
      nickname: "Takma Ad",
      nickname_placeholder: "Takma Ad Girin",
      currency: "Para Birimi",
      currency_placeholder: "Para Birimi Girin",
    },
  },
};
