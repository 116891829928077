export const lobbyManagement = {
  lobbyManagement: {
    edit: {
      title: "Lobi Düzenle",
      name: "Ad",
      tenant: "Tenant",
      lobbyUrl: "Lobi URL",
      userDetailsUrl: "Kullanıcı Detayları URL",
      transactionUrl: "İşlem URL",
      getBalanceUrl: "Bakiye URL'sini Al",
      rakeInfoUrl: "Rake Bilgisi URL",
      contactEmail: "İletişim E-posta",
      maxNumofTables: "Maksimum Masa Sayısı",
      enabledGameTypes: "Etkin Oyun Türleri",
      skincode: "Skin Kodu",
      note: "Not",
    },
    add: {
      title: "Lobi Ekle",
      name: "Ad",
      name_placeholder: "Ad Girin",
      name_required: "Lütfen ad girin",
      tenant: "Tenant",
      tenant_placeholder: "Tenant Girin",
      tenant_required: "Lütfen tenant girin",
      lobbyUrl: "Lobi URL",
      lobbyUrl_placeholder: "Lobi URL'sini Girin",
      lobbyUrl_required: "Lütfen lobi URL'sini girin",
      userDetailsUrl: "Kullanıcı Detayları URL",
      userDetailsUrl_placeholder: "Kullanıcı Detayları URL'sini Girin",
      transactionUrl: "İşlem URL",
      transactionUrl_placeholder: "İşlem URL'sini Girin",
      getBalanceUrl: "Bakiye URL'sini Al",
      getBalanceUrl_placeholder: "Bakiye URL'sini Girin",
      rakeInfoUrl: "Rake Bilgisi URL",
      rakeInfoUrl_placeholder: "Rake Bilgisi URL'sini Girin",
      contactEmail: "İletişim E-posta",
      contactEmail_placeholder: "İletişim E-postasını Girin",
      contactEmail_required: "Lütfen iletişim e-postasını girin",
      maxNumofTables: "Maksimum Masa Sayısı",
      maxNumofTables_placeholder: "Maksimum Masa Sayısını Girin",
      maxNumofTables_required: "Lütfen maksimum masa sayısını girin",
      enabledGameTypes: "Etkin Oyun Türleri",
      enabledGameTypes_placeholder: "Etkin Oyun Türlerini Girin",
      enabledGameTypes_required: "Lütfen etkin oyun türlerini girin",
      skincode: "Skin Kodu",
      skincode_placeholder: "Skin Kodunu Girin",
      skincode_required: "Lütfen skin kodunu girin",
      note: "Not",
      note_placeholder: "Not Girin",
      success: "Lobi başarıyla eklendi",
      error: "Lobi eklenirken hata oluştu",
    },
    columns: {
      id: "Kimlik Görüntüleyici",
      tenant: "Tenant",
      name: "Ad",
      enabledGameTypes: "Etkin Oyun Türleri",
      tables: "Masalar",
      maxNumofTables: "Maksimum Masa Sayısı",
      skincode: "Skin Kodu",
      lobbyUrl: "Lobi URL",
      userDetailsUrl: "Kullanıcı Detayları URL",
      transactionUrl: "İşlem URL",
      getBalanceUrl: "Bakiye URL'sini Al",
      rakeInfoUrl: "Rake Bilgisi URL",
      createdAt: "Oluşturulma Tarihi",
      updatedAt: "Güncellenme Tarihi",
    },
  },
};
