/* tslint:disable */
/* eslint-disable */
/**
 * PokerWise Backoffice Player Management
 * API for managing PokerWise players from backoffice.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * PlayerControllerApi - axios parameter creator
 * @export
 */
export var PlayerControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Retrieve players by block status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersByblockGet: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/players/byblock";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve players by currency
         * @param {string} currency Currency code (e.g., USD, EUR)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBycurrCurrencyGet: function (currency_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([currency_1], args_1, true), void 0, function (currency, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'currency' is not null or undefined
                            assertParamExists('playersBycurrCurrencyGet', 'currency', currency);
                            localVarPath = "/players/bycurr/{currency}"
                                .replace("{".concat("currency", "}"), encodeURIComponent(String(currency)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve a player by their unique ID
         * @param {string} id Unique ID of the player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersByidIdGet: function (id_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([id_1], args_1, true), void 0, function (id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('playersByidIdGet', 'id', id);
                            localVarPath = "/players/byid/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve players by lobby ID
         * @param {string} lobbyId Lobby ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBylobbyLobbyIdGet: function (lobbyId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([lobbyId_1], args_1, true), void 0, function (lobbyId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'lobbyId' is not null or undefined
                            assertParamExists('playersBylobbyLobbyIdGet', 'lobbyId', lobbyId);
                            localVarPath = "/players/bylobby/{lobby_id}"
                                .replace("{".concat("lobby_id", "}"), encodeURIComponent(String(lobbyId)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve a player by username
         * @param {string} username Username of the player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynameUsernameGet: function (username_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([username_1], args_1, true), void 0, function (username, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'username' is not null or undefined
                            assertParamExists('playersBynameUsernameGet', 'username', username);
                            localVarPath = "/players/byname/{username}"
                                .replace("{".concat("username", "}"), encodeURIComponent(String(username)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update player details by username
         * @param {string} username Username of the player
         * @param {PlayerUpdate} playerUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynameUsernamePut: function (username_1, playerUpdate_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([username_1, playerUpdate_1], args_1, true), void 0, function (username, playerUpdate, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'username' is not null or undefined
                            assertParamExists('playersBynameUsernamePut', 'username', username);
                            // verify required parameter 'playerUpdate' is not null or undefined
                            assertParamExists('playersBynameUsernamePut', 'playerUpdate', playerUpdate);
                            localVarPath = "/players/byname/{username}"
                                .replace("{".concat("username", "}"), encodeURIComponent(String(username)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(playerUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve players by their creation time
         * @param {string} nickname Username of the player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynicknameNicknameGet: function (nickname_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([nickname_1], args_1, true), void 0, function (nickname, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'nickname' is not null or undefined
                            assertParamExists('playersBynicknameNicknameGet', 'nickname', nickname);
                            localVarPath = "/players/bynickname/{nickname}"
                                .replace("{".concat("nickname", "}"), encodeURIComponent(String(nickname)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve all players
         * @param {number} [limit] The maximum number of players to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersGet: function (limit_1, lastKey_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([limit_1, lastKey_1], args_1, true), void 0, function (limit, lastKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/players";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (lastKey !== undefined) {
                                localVarQueryParameter['lastKey'] = lastKey;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * PlayerControllerApi - functional programming interface
 * @export
 */
export var PlayerControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = PlayerControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Retrieve players by block status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersByblockGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersByblockGet(options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersByblockGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve players by currency
         * @param {string} currency Currency code (e.g., USD, EUR)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBycurrCurrencyGet: function (currency, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersBycurrCurrencyGet(currency, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersBycurrCurrencyGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve a player by their unique ID
         * @param {string} id Unique ID of the player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersByidIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersByidIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersByidIdGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve players by lobby ID
         * @param {string} lobbyId Lobby ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBylobbyLobbyIdGet: function (lobbyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersBylobbyLobbyIdGet(lobbyId, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersBylobbyLobbyIdGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve a player by username
         * @param {string} username Username of the player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynameUsernameGet: function (username, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersBynameUsernameGet(username, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersBynameUsernameGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update player details by username
         * @param {string} username Username of the player
         * @param {PlayerUpdate} playerUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynameUsernamePut: function (username, playerUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersBynameUsernamePut(username, playerUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersBynameUsernamePut']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve players by their creation time
         * @param {string} nickname Username of the player
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynicknameNicknameGet: function (nickname, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersBynicknameNicknameGet(nickname, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersBynicknameNicknameGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve all players
         * @param {number} [limit] The maximum number of players to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersGet: function (limit, lastKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.playersGet(limit, lastKey, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['PlayerControllerApi.playersGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
/**
 * PlayerControllerApi - factory interface
 * @export
 */
export var PlayerControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = PlayerControllerApiFp(configuration);
    return {
        /**
         *
         * @summary Retrieve players by block status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersByblockGet: function (options) {
            return localVarFp.playersByblockGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve players by currency
         * @param {PlayerControllerApiPlayersBycurrCurrencyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBycurrCurrencyGet: function (requestParameters, options) {
            return localVarFp.playersBycurrCurrencyGet(requestParameters.currency, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve a player by their unique ID
         * @param {PlayerControllerApiPlayersByidIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersByidIdGet: function (requestParameters, options) {
            return localVarFp.playersByidIdGet(requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve players by lobby ID
         * @param {PlayerControllerApiPlayersBylobbyLobbyIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBylobbyLobbyIdGet: function (requestParameters, options) {
            return localVarFp.playersBylobbyLobbyIdGet(requestParameters.lobbyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve a player by username
         * @param {PlayerControllerApiPlayersBynameUsernameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynameUsernameGet: function (requestParameters, options) {
            return localVarFp.playersBynameUsernameGet(requestParameters.username, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update player details by username
         * @param {PlayerControllerApiPlayersBynameUsernamePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynameUsernamePut: function (requestParameters, options) {
            return localVarFp.playersBynameUsernamePut(requestParameters.username, requestParameters.playerUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve players by their creation time
         * @param {PlayerControllerApiPlayersBynicknameNicknameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersBynicknameNicknameGet: function (requestParameters, options) {
            return localVarFp.playersBynicknameNicknameGet(requestParameters.nickname, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve all players
         * @param {PlayerControllerApiPlayersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playersGet: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.playersGet(requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PlayerControllerApi - object-oriented interface
 * @export
 * @class PlayerControllerApi
 * @extends {BaseAPI}
 */
var PlayerControllerApi = /** @class */ (function (_super) {
    __extends(PlayerControllerApi, _super);
    function PlayerControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Retrieve players by block status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersByblockGet = function (options) {
        var _this = this;
        return PlayerControllerApiFp(this.configuration).playersByblockGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve players by currency
     * @param {PlayerControllerApiPlayersBycurrCurrencyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersBycurrCurrencyGet = function (requestParameters, options) {
        var _this = this;
        return PlayerControllerApiFp(this.configuration).playersBycurrCurrencyGet(requestParameters.currency, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve a player by their unique ID
     * @param {PlayerControllerApiPlayersByidIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersByidIdGet = function (requestParameters, options) {
        var _this = this;
        return PlayerControllerApiFp(this.configuration).playersByidIdGet(requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve players by lobby ID
     * @param {PlayerControllerApiPlayersBylobbyLobbyIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersBylobbyLobbyIdGet = function (requestParameters, options) {
        var _this = this;
        return PlayerControllerApiFp(this.configuration).playersBylobbyLobbyIdGet(requestParameters.lobbyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve a player by username
     * @param {PlayerControllerApiPlayersBynameUsernameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersBynameUsernameGet = function (requestParameters, options) {
        var _this = this;
        return PlayerControllerApiFp(this.configuration).playersBynameUsernameGet(requestParameters.username, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update player details by username
     * @param {PlayerControllerApiPlayersBynameUsernamePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersBynameUsernamePut = function (requestParameters, options) {
        var _this = this;
        return PlayerControllerApiFp(this.configuration).playersBynameUsernamePut(requestParameters.username, requestParameters.playerUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve players by their creation time
     * @param {PlayerControllerApiPlayersBynicknameNicknameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersBynicknameNicknameGet = function (requestParameters, options) {
        var _this = this;
        return PlayerControllerApiFp(this.configuration).playersBynicknameNicknameGet(requestParameters.nickname, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve all players
     * @param {PlayerControllerApiPlayersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerControllerApi
     */
    PlayerControllerApi.prototype.playersGet = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return PlayerControllerApiFp(this.configuration).playersGet(requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PlayerControllerApi;
}(BaseAPI));
export { PlayerControllerApi };
