var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import bannerImage from "@/assets/images/sign-in-banner.png";
import styled from "styled-components";
export var SignInContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  background-color: #fff;\n  width: 100vw;\n\n  @media (max-width: 1024px) {\n    flex-direction: column;\n  }\n"], ["\n  height: 100vh;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  background-color: #fff;\n  width: 100vw;\n\n  @media (max-width: 1024px) {\n    flex-direction: column;\n  }\n"])));
export var BannerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 50%;\n  height: 100%;\n  background-image: url(", ");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  position: relative;\n\n  @media (max-width: 1024px) {\n    width: 100%;\n  }\n"], ["\n  width: 50%;\n  height: 100%;\n  background-image: url(", ");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  position: relative;\n\n  @media (max-width: 1024px) {\n    width: 100%;\n  }\n"])), bannerImage);
export var TenantLogo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 64px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"], ["\n  height: 64px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"])));
export var WiseLogo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 24px;\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  margin-bottom: 30px;\n"], ["\n  height: 24px;\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  margin-bottom: 30px;\n"])));
export var LoginWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n  width: 50%;\n  padding-bottom: 30px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n  width: 50%;\n  padding-bottom: 30px;\n"])));
export var StyledForm = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 336px;\n\n  @media (max-width: 375px) {\n    width: 250px;\n  }\n\n  .ant-btn {\n    @media (max-width: 375px) {\n      font-size: 12px;\n      padding: 0px;\n    }\n  }\n\n  h1 {\n    line-height: 56px;\n    letter-spacing: 1px;\n    margin-bottom: 15px;\n  }\n"], ["\n  width: 336px;\n\n  @media (max-width: 375px) {\n    width: 250px;\n  }\n\n  .ant-btn {\n    @media (max-width: 375px) {\n      font-size: 12px;\n      padding: 0px;\n    }\n  }\n\n  h1 {\n    line-height: 56px;\n    letter-spacing: 1px;\n    margin-bottom: 15px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
