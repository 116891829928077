import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDown, Avatar, Dropdown, Header, Space, useWindowDimensions } from "@atom/ui-kit";
import { changeLanguage } from "i18next";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { languageData } from "./AppHeader.constants";
import { TOGGLE_BUTTON } from "./AppHeader.style";
var AppHeader = function (_a) {
    var _b;
    var collapsed = _a.collapsed, setCollapsed = _a.setCollapsed, lang = _a.lang, menu = _a.menu;
    var _c = useState(null), searchText = _c[0], setSearchText = _c[1];
    var width = useWindowDimensions().width;
    var location = useLocation();
    var navigate = useNavigate();
    var t = useTranslation().t;
    var openPageKey = location.pathname.split("/").pop();
    var activePageTitle = (_b = menu.find(function (item) { return item.key === openPageKey; })) === null || _b === void 0 ? void 0 : _b.label;
    var handleToggleSidebar = useCallback(function () { return setCollapsed(!collapsed); }, [collapsed, setCollapsed]);
    var handleChangeLanguage = useCallback(function (e) {
        changeLanguage(e.key);
        localStorage.setItem("language", e.key);
    }, [location.pathname]);
    var handleSearchTextChange = function (e) { return setSearchText(e.target.value); };
    var handleSearchInUsers = function (evt) {
        if (evt.charCode === 13 && searchText) {
            navigate("/user-management", { state: { searchText: searchText } });
        }
    };
    return (_jsx(Header, { title: activePageTitle, startContent: _jsx(TOGGLE_BUTTON, { onClick: handleToggleSidebar, children: _jsx(ArrowDown, { className: collapsed ? "arrow-right" : "arrow-left" }) }), endContent: width > 768 && (_jsxs(_Fragment, { children: [_jsx(Avatar, { src: "https://api.multiavatar.com/".concat(new Date().toDateString(), ".svg") }), _jsx(Dropdown.Button, { size: "small", menu: {
                        items: languageData.map(function (_a) {
                            var locale = _a.locale, icon = _a.icon, name = _a.name;
                            return ({
                                key: locale,
                                label: _jsx(Space, { children: name }),
                            });
                        }),
                        onClick: handleChangeLanguage,
                    }, children: _jsx(Space, { align: "start", children: localStorage.getItem("language") == "en" ? "EN" : "TR" }) })] })), searchInputProps: {
            placeholder: t("sidebar.search_input_placeholder"),
            onChange: handleSearchTextChange,
            onKeyPress: handleSearchInUsers,
            value: searchText || "",
        } }));
};
export default AppHeader;
