var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IdView from "@/components/IdViewer";
import InfinityTable from "@/components/Infinitytable/InfinityTable";
import { ArrowLeftOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Badge, Button, Tag } from "@atom/ui-kit";
import { Col, Divider, Drawer, Row, Tooltip } from "antd";
import dayjs from "dayjs";
var TableManagementDetail = function (_a) {
    var _b;
    var open = _a.open, recordData = _a.recordData, lobbyData = _a.lobbyData, handleEdit = _a.handleEdit, handleDelete = _a.handleDelete, t = _a.t, onClose = _a.onClose;
    var columns = function (t) { return [
        { title: t("tableManagement.columns.lobby"), dataIndex: "lobby_id", key: "lobby_id", width: 180, render: function (name) { var _a, _b; return ((_b = (_a = lobbyData === null || lobbyData === void 0 ? void 0 : lobbyData.items) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === name; })) === null || _b === void 0 ? void 0 : _b.name) || _jsx(IdView, { id: name }); } },
        { title: t("tableManagement.columns.name"), dataIndex: "name", key: "name", width: 180, render: function (name) { return _jsx(IdView, { id: name }); } },
        { title: t("tableManagement.columns.id"), dataIndex: "id", key: "id", width: 180, render: function (id) { return _jsx(IdView, { id: id }); } },
        { title: t("tableManagement.columns.type"), dataIndex: "type", key: "type", width: 150 },
        {
            title: t("tableManagement.columns.blinds"),
            dataIndex: "blinds",
            key: "blinds",
            width: 180,
            render: function (blinds, record) { return (_jsxs("span", { children: [blinds.small, record.currency, "/", blinds.big, record.currency] })); },
        },
        {
            title: t("tableManagement.columns.buyInRange"),
            dataIndex: "minBuyIn",
            key: "buyInRange",
            width: 180,
            render: function (_, record) { return (_jsxs("div", { children: [record.minBuyIn, " ", record.currency, " - ", record.maxBuyIn, " ", record.currency] })); },
        },
        {
            title: t("tableManagement.columns.players"),
            dataIndex: "totalSeats",
            key: "totalSeats",
            width: 120,
            render: function (_, record) { return (_jsxs("span", { children: [record.currentAllocatedSeats, " / ", record.totalSeats] })); },
        },
        { title: t("tableManagement.columns.currency"), dataIndex: "currency", key: "currency", width: 100, render: function (currency) { return _jsx(Tag, { color: "gold", children: currency }); } },
        { title: t("tableManagement.columns.totalSeats"), dataIndex: "totalSeats", key: "totalSeats", width: 120 },
        { title: t("tableManagement.columns.waitingPlayersCount"), dataIndex: "waitingPlayersCount", key: "waitingPlayersCount", width: 150 },
        { title: t("tableManagement.columns.timeoutDefaultAction"), dataIndex: "timeoutDefaultAction", key: "timeoutDefaultAction", width: 150, render: function (action) { return _jsx(Tag, { color: action === "Fold" ? "red" : "green", children: action }); } },
        { title: t("tableManagement.columns.minPlayers"), dataIndex: "minPlayers", key: "minPlayers", width: 120 },
        { title: t("tableManagement.columns.createdBy"), dataIndex: "createdBy", key: "createdBy", width: 150, render: function (createdBy) { return _jsx(Tag, { color: "blue", children: createdBy.split("#")[1] }); } },
        {
            title: t("tableManagement.columns.isPasswordProtected"),
            dataIndex: "isPasswordProtected",
            key: "isPasswordProtected",
            width: 180,
            render: function (isPasswordProtected) { return (_jsx(Tooltip, { title: isPasswordProtected ? "Protected" : "Not Protected", children: isPasswordProtected ? _jsx(LockOutlined, { style: { color: "red" } }) : _jsx(UnlockOutlined, { style: { color: "green" } }) })); },
        },
        { title: t("tableManagement.columns.highlighted"), dataIndex: "highlighted", key: "highlighted", width: 120, render: function (highlighted) { return _jsx(Badge, { status: highlighted ? "success" : "default", text: highlighted ? "Yes" : "No" }); } },
        { title: t("tableManagement.columns.autoRetable"), dataIndex: "autoRetable", key: "autoRetable", width: 120, render: function (autoRetable) { return _jsx(Tag, { color: autoRetable ? "green" : "red", children: autoRetable ? "Yes" : "No" }); } },
        { title: t("tableManagement.columns.currentAllocatedSeats"), dataIndex: "currentAllocatedSeats", key: "currentAllocatedSeats", width: 150 },
        { title: t("tableManagement.columns.skincode"), dataIndex: "skincode", key: "skincode", width: 120, render: function (skincode) { return _jsx(Tag, { color: "purple", children: skincode }); } },
        { title: t("tableManagement.columns.serviceFeePercent"), dataIndex: "serviceFeePercent", key: "serviceFeePercent", width: 150, render: function (fee) { return _jsxs(Tag, { color: "orange", children: [fee, "%"] }); } },
        { title: t("tableManagement.columns.createdAt"), dataIndex: "createdAt", key: "createdAt", width: 180, render: function (createdAt) { return dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"); } },
        { title: t("tableManagement.columns.timeLimitMs"), dataIndex: "timeLimitMs", key: "timeLimitMs", width: 150 },
        { title: t("tableManagement.columns.tenant"), dataIndex: "tenant", key: "tenant", width: 130 },
        { title: t("tableManagement.columns.pot"), dataIndex: "pot", key: "pot", width: 100 },
        { title: t("tableManagement.columns.limitType"), dataIndex: "limitType", key: "limitType", width: 150, render: function (type) { return _jsx(Tag, { color: type === "nolimit" ? "red" : "blue", children: type }); } },
        { title: t("tableManagement.columns.category"), dataIndex: "category", key: "category", width: 150, render: function (category) { return _jsx(Tag, { color: "cyan", children: category }); } },
    ]; };
    var columnsArray = columns(t);
    var tableOptions = {
        containerStyle: {
            height: "auto",
        },
        scroll: { x: 800, y: 300 },
        dataSource: recordData,
        pagination: false,
    };
    return (_jsx(Drawer, { width: 900, open: open, onClose: onClose, closeIcon: _jsx(ArrowLeftOutlined, {}), title: ((_b = recordData[0]) === null || _b === void 0 ? void 0 : _b.name) + " " + t("root.details"), children: _jsxs("div", { style: {
                display: "flex",
                flexDirection: "column",
            }, children: [_jsx(InfinityTable, __assign({}, tableOptions, { columns: columnsArray.slice(0, 6) })), _jsx(Divider, {}), _jsx(InfinityTable, __assign({}, tableOptions, { columns: columnsArray.slice(6, 12) })), _jsx(Divider, {}), _jsx(InfinityTable, __assign({}, tableOptions, { columns: columnsArray.slice(12, 18) })), _jsx(Divider, {}), _jsx(InfinityTable, __assign({}, tableOptions, { columns: columnsArray.slice(18, columnsArray.length) })), _jsx(Divider, {}), _jsxs(Row, { gutter: 10, children: [_jsx(Col, { sm: 12, children: _jsx(Button, { block: true, buttonType: "dashed", buttonSize: "small", onClick: function () { return handleEdit(recordData[0].id, recordData[0].tenant); }, children: t("root.edit_button") }, "edit") }), _jsx(Col, { sm: 12, children: _jsx(Button, { block: true, buttonType: "primary", buttonSize: "small", onClick: function () { return handleDelete(recordData[0].id); }, children: t("root.delete_button") }, "delete") })] })] }) }));
};
export default TableManagementDetail;
