import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "@atom/ui-kit";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
var UserManagementFilterSpace = function (_a) {
    var onSearch = _a.onSearch, onAddNewData = _a.onAddNewData;
    var form = Form.useForm()[0];
    var t = useTranslation().t;
    var handleSearch = function (values) {
        if (onSearch)
            onSearch(values);
    };
    var handleFieldChange = debounce(function (changedFields) {
        var _a;
        var changedField = changedFields[0];
        // Değişiklik yapılan alana göre diğer alanları sıfırla
        if (changedField) {
            form.setFieldsValue({
                userId: changedField.name[0] === "userId" ? form.getFieldValue("userId") : undefined,
                userName: changedField.name[0] === "userName" ? form.getFieldValue("userName") : undefined,
            });
            // Değişiklikleri onSearch işlevine gönder
            onSearch === null || onSearch === void 0 ? void 0 : onSearch((_a = {},
                _a[changedField.name[0]] = changedField.value,
                _a));
        }
    }, 500);
    return (_jsxs(Form, { form: form, layout: "inline", onFieldsChange: handleFieldChange, onFinish: handleSearch, style: { width: "100%", justifyContent: "space-between" }, children: [_jsxs(Row, { gutter: [10, 10], children: [_jsx(Col, { xs: 12, children: _jsx(Form.Item, { name: "userId", children: _jsx(Input, { size: "small", variant: "borderless", placeholder: t("userManagement.filters.userId_placeholder") }) }) }), _jsx(Col, { xs: 12, children: _jsx(Form.Item, { name: "userName", children: _jsx(Input, { size: "small", variant: "borderless", placeholder: t("userManagement.filters.userName_placeholder") }) }) })] }), _jsxs(Row, { children: [_jsx(Col, { sm: 12, children: _jsx(Form.Item, { children: _jsx(Button, { buttonSize: "small", icon: _jsx(ReloadOutlined, {}), htmlType: "submit", children: t("root.refresh_button") }) }) }), _jsx(Col, { sm: 12, children: _jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(PlusOutlined, {}), type: "primary", onClick: onAddNewData, children: t("root.add_button") }) })] })] }));
};
export default UserManagementFilterSpace;
