export const userManagement = {
  userManagement: {
    edit: {
      title: "Edit User",
      username: "Username",
      username_required: "Please enter Username",
      email: "Email",
      email_required: "Please enter Email",
      role: "Role",
      role_required: "Please enter Role",
      password: "Password",
      password_required: "Please enter Password",
      organization: "Organization",
      organization_required: "Please enter Organization",
      tenant: "Tenant",
      tenant_required: "Please enter Tenant",
      lobby: "Lobby",
      lobby_required: "Please enter Lobby",
      passwordExpirationDate: "Password Expiration Date",
      is_first_password: "Is First Password",
      is_raw_password: "Is Raw Password",
      whitelistedIPs: "Whitelisted IPs",
      locale: "Locale",
      language: "Language",
      telephone: "Telephone",
      note: "Note",
      createdBy: "Created By",
    },
    add: {
      title: "Add User",
      username: "Username",
      username_placeholder: "Enter Username",
      username_required: "Please enter Username",
      email: "Email",
      email_placeholder: "Enter Email",
      email_required: "Please enter Email",
      role: "Role",
      role_placeholder: "Enter Role",
      role_required: "Please enter Role",
      password: "Password",
      password_placeholder: "Enter Password",
      password_required: "Please enter Password",
      organization: "Organization",
      organization_placeholder: "Enter Organization",
      organization_required: "Please enter Organization",
      tenant: "Tenant",
      tenant_placeholder: "Enter Tenant",
      tenant_required: "Please enter Tenant",
      lobby: "Lobby",
      lobby_placeholder: "Enter Lobby",
      lobby_required: "Please enter Lobby",
      passwordExpirationDate: "Password Expiration Date",
      passwordExpirationDate_placeholder: "Enter Password Expiration Date",
      is_first_password: "Is First Password",
      is_first_password_placeholder: "Enter Is First Password",
      is_raw_password: "Is Raw Password",
      is_raw_password_placeholder: "Enter Is Raw Password",
      whitelistedIPs: "Whitelisted IPs",
      whitelistedIPs_placeholder: "Enter Whitelisted IPs",
      locale: "Locale",
      locale_placeholder: "Enter Locale",
      language: "Language",
      language_placeholder: "Enter Language",
      telephone: "Telephone",
      telephone_placeholder: "Enter Telephone",
      note: "Note",
      note_placeholder: "Enter Note",
      createdBy: "Created By",
      createdBy_placeholder: "Enter Created By",
      success: "UserManagement added successfully",
      error: "Error adding UserManagement",
    },
    columns: {
      id: "ID Viewer",
      tenant: "Tenant",
      username: "Username",
      email: "Email",
      role: "Role",
      createdAt: "Created At",
      updatedAt: "Updated At",
    },
    filters: {
      userName_placeholder: "User Name",
      userId_placeholder: "User ID",
    },
  },
};
