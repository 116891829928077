import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getUser } from "@/utils/storage";
import { useTranslation } from "react-i18next";
import { Container } from "./Dashboard.style";
var Dashboard = function () {
    var user = getUser();
    var t = useTranslation().t;
    return (_jsxs(Container, { children: [_jsxs("h1", { children: [t("dashboard.title", { name: user.username }), " !"] }), _jsx("p", { children: t("dashboard.description") })] }));
};
export default Dashboard;
