import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/en";
import tr from "./locales/tr/tr";
i18n
    .use(HttpBackend)
    .use(LocalStorageBackend) // LocalStorage backend'i ekle
    .use(initReactI18next)
    .init({
    fallbackLng: "en",
    debug: true,
    resources: {
        en: {
            translation: en,
        },
        tr: {
            translation: tr,
        },
    },
    backend: {
        backends: [LocalStorageBackend, HttpBackend], // Önce localStorage'a bak, sonra HTTP backend'e geç
        backendOptions: [
            {
                expirationTime: 7 * 24 * 60 * 60 * 1000, // 1 hafta boyunca cache'de tut
            },
            {
                loadPath: "/i18n/locales/{{lng}}/{{lng}}.js", // HTTP backend için yükleme yolu
            },
        ],
    },
});
export default i18n;
