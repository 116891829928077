"use client";
import { useEffect } from "react";
var useZoomLevel = function () {
    useEffect(function () {
        var storedZoom = localStorage.getItem("zoom-level");
        var storedBase = localStorage.getItem("base-level");
        if (storedZoom) {
            document.querySelector(":root").style.setProperty("--zoom-level", storedZoom);
        }
        if (storedBase) {
            document.querySelector(":root").style.setProperty("--base-level", storedBase);
        }
    }, []);
};
export default useZoomLevel;
