export const tenantManagement = {
  tenantManagement: {
    edit: {
      title: "Edit Tenant",
      name: "Name",
      name_required: "Please enter Name",
      id: "ID",
      baseUrl: "Base URL",
      hmacSecret: "HMAC Secret",
      whitelistedIPs: "Whitelisted IPs",
      contactEmail: "Contact Email",
      maxNumofTables: "Max Number of Tables",
      enabledGameTypes: "Enabled Game Types",
    },
    add: {
      title: "Add Tenant",
      name: "Name",
      name_placeholder: "Enter Name",
      name_required: "Please enter Name",
      baseUrl: "Base URL",
      baseUrl_placeholder: "Enter Base URL",
      baseUrl_required: "Please enter Base URL",
      hmacSecret: "HMAC Secret",
      hmacSecret_placeholder: "Enter HMAC Secret",
      hmacSecret_required: "Please enter HMAC Secret",
      whitelistedIPs: "Whitelisted IPs",
      whitelistedIPs_placeholder: "Enter Whitelisted IPs",
      whitelistedIPs_required: "Please enter Whitelisted IPs",
      contactEmail: "Contact Email",
      contactEmail_placeholder: "Enter Contact Email",
      contactEmail_required: "Please enter Contact Email",
      maxNumofTables: "Max Number of Tables",
      maxNumofTables_placeholder: "Enter Max Number of Tables",
      maxNumofTables_required: "Please enter Max Number of Tables",
      enabledGameTypes: "Enabled Game Types",
      enabledGameTypes_placeholder: "Enter Enabled Game Types",
      enabledGameTypes_required: "Please enter Enabled Game Types",
      note: "Note",
      note_placeholder: "Enter Note",
      success: "TenantManagement added successfully",
      error: "Error adding TenantManagement",
    },
    columns: {
      id: "ID Viewer",
      createdAt: "Created At",
      name: "Name",
      baseUrl: "Base URL",
      whitelistedIPs: "Whitelisted IPs",
      enabledGameTypes: "Enabled Game Types",
      hmacSecret: "HMAC Secret",
      contactEmail: "Contact Email",
    },
    filters: {
      refresh_button: "Refresh",
      add_button: "Add TenantManagement",
    },
  },
};
