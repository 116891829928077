import { jsx as _jsx } from "react/jsx-runtime";
import { theme, ThemeProvider } from "@atom/ui-kit";
import "core-js";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import "./global.scss";
import AppRouter from "./pages/routes";
var App = function () {
    return (_jsx(BrowserRouter, { children: _jsx(ThemeProvider, { children: _jsx(StyledComponentThemeProvider, { theme: theme, children: _jsx(AppRouter, {}) }) }) }));
};
var container = document.getElementById("app");
var root = createRoot(container);
root.render(_jsx(App, {}));
