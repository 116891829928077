export const signIn = {
  signIn: {
    root: {
      title: "Sign In",
      logoAltText: "Tenant Logo",
      errorMessage: "Sign-in failed. Please check your information.",
    },
    loginForm: {
      usernameErrorMessage: "The input is not a valid Username!",
      usernamePlaceholder: "Username",
      passwordErrorMessage: "Please input your Password!",
      passwordPlaceholder: "Password",
      signInButton: "Sign In",
    },
    tenantForm: {
      tenantErrorMessage: "The input is not a valid Tenant!",
      tenantPlaceholder: "Select Tenant",
      nextButton: "Next",
    },
  },
};
