"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GameControllerApiFactory } from "@/apis/game-management";
import InfinityTable from "@/components/Infinitytable";
import { useApi } from "@/hooks/useApi";
import { Card, Divider, message, Modal } from "@atom/ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { columns } from "./GameManagement.columns";
import GameManagementFilterSpace from "./GameManagement.filters";
var DEFAULT_PAGE_SIZE = 50;
var GameManagement = function () {
    var navigate = useNavigate();
    var _a = useState({
        tenant: "wisegaming",
        limit: DEFAULT_PAGE_SIZE,
        requestType: "all",
    }), reqParams = _a[0], setReqParams = _a[1];
    var t = useTranslation().t;
    var _b = useState([]), gameData = _b[0], setGameData = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    // Dinamik API çağrısı yapan fonksiyon
    var fetchGameData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 9, 10, 11]);
                    setLoading(true);
                    response = void 0;
                    _a = reqParams.requestType;
                    switch (_a) {
                        case "all": return [3 /*break*/, 1];
                        case "gameId": return [3 /*break*/, 3];
                        case "gameName": return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 7];
                case 1: return [4 /*yield*/, GameControllerApiFactory().gamesTenantGet(reqParams)];
                case 2:
                    response = (_b.sent());
                    setGameData(response.data.data.games);
                    return [3 /*break*/, 8];
                case 3: return [4 /*yield*/, GameControllerApiFactory().gamesByidTenantIdGet({
                        id: reqParams.gameId,
                        tenant: reqParams.tenant,
                    })];
                case 4:
                    response = (_b.sent());
                    setGameData([response.data.data]);
                    return [3 /*break*/, 8];
                case 5: return [4 /*yield*/, GameControllerApiFactory().gamesBynameTenantNameGet({
                        name: reqParams.gameName,
                        tenant: reqParams.tenant,
                    })];
                case 6:
                    response = (_b.sent());
                    setGameData([response.data.data]);
                    return [3 /*break*/, 8];
                case 7:
                    message.error("Invalid request type");
                    return [2 /*return*/];
                case 8: return [3 /*break*/, 11];
                case 9:
                    error_1 = _b.sent();
                    message.error("Failed to fetch game data");
                    return [3 /*break*/, 11];
                case 10:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 11: return [2 /*return*/];
            }
        });
    }); };
    // Delete GameManagement data
    var _d = useApi({
        asyncFunction: GameControllerApiFactory().gamesByidTenantIdDelete, // set async function here
        successCallback: function (response) {
            message.success("Data deleted successfully");
            fetchGameData(); // Silindikten sonra listeyi yenile
        },
        errorCallback: function (error) {
            message.error("Error deleting selected item");
        },
    }), callDeleteGameManagement = _d.call, deleteGameManagementLoading = _d.loading;
    var handleDelete = function (id) {
        Modal.confirm({
            title: t("root.delete_confirmation_title"),
            content: t("root.delete_confirmation_message"),
            okText: t("root.confirm"),
            okType: "danger",
            cancelText: t("root.cancel"),
            onOk: function () {
                callDeleteGameManagement({
                    tenant: "wisegaming",
                    id: id,
                });
            },
        });
    };
    var handleEdit = function (key, tenant) {
        navigate("/game-management/".concat(key, "?tenant=").concat(tenant));
    };
    var handleAdd = function () {
        navigate("/game-management/add");
    };
    var handleSearch = function (values) {
        setReqParams(__assign(__assign(__assign({}, reqParams), values), { requestType: values.gameId ? "gameId" : values.gameName ? "gameName" : "all" }));
    };
    useEffect(function () {
        fetchGameData();
    }, [reqParams]);
    return (_jsxs(Card, { style: {
            padding: "20px 0",
            height: "80vh",
        }, children: [_jsx(GameManagementFilterSpace, { onAddNewData: handleAdd, onSearch: handleSearch }), _jsx(Divider, {}), _jsx(InfinityTable, { columns: columns(handleEdit, handleDelete, t), dataSource: gameData || [], loading: loading || deleteGameManagementLoading })] }));
};
export default GameManagement;
