export const gameManagement = {
  gameManagement: {
    edit: {
      title: "Oyunu Düzenle",
      id: "Kimlik",
      id_required: "Lütfen kimlik girin",
      table_id: "Masa Kimliği",
      players: "Oyuncular",
      last_bet_amount: "Son Bahis Miktarı",
      dealer_position: "Dağıtıcı Pozisyonu",
      current_turn: "Mevcut Tur",
      community_cards: "Topluluk Kartları",
      pot: "Pot",
      round: "Tur",
    },
    add: {
      title: "Oyun Ekle",
      table_id: "Masa Kimliği",
      table_id_placeholder: "Masa Kimliği Girin",
      players: "Oyuncular",
      players_placeholder: "Oyuncuları Girin",
      last_bet_amount: "Son Bahis Miktarı",
      last_bet_amount_placeholder: "Son Bahis Miktarını Girin",
      dealer_position: "Dağıtıcı Pozisyonu",
      dealer_position_placeholder: "Dağıtıcı Pozisyonunu Girin",
      current_turn: "Mevcut Tur",
      current_turn_placeholder: "Mevcut Turu Girin",
      community_cards: "Topluluk Kartları",
      community_cards_placeholder: "Topluluk Kartlarını Girin",
      pot: "Pot",
      pot_placeholder: "Pot Girin",
      round: "Tur",
      round_placeholder: "Tur Girin",
      success: "Oyun başarıyla eklendi",
      error: "Oyun eklenirken hata oluştu",
    },
    columns: {
      tenant: "Tenant",
      id: "Kimlik",
      table_id: "Masa Kimliği",
      lobby_id: "Lobi Kimliği",
      name: "Ad",
      type: "Tür",
      phase: "Aşama",
      currency: "Para Birimi",
      category: "Kategori",
      limitType: "Limit Türü",
      version: "Versiyon",
      skincode: "Cilt Kodu",
      organization: "Organizasyon",
      players: "Oyuncular",
      deck: "Deste",
      community_cards: "Topluluk Kartları",
      lastBetAmount: "Son Bahis Miktarı",
      pot: "Pot",
      serviceFeePercent: "Hizmet Ücreti Yüzdesi",
      totalSeats: "Toplam Koltuk Sayısı",
      currentPlayerIndex: "Mevcut Oyuncu İndeksi",
      currentTurn: "Mevcut Tur",
      timeLimitMs: "Süre Sınırı (ms)",
      minBuyIn: "Minimum Giriş",
      maxBuyIn: "Maksimum Giriş",
      ante: "Ante",
      timeoutDefaultAction: "Zaman Aşımı Varsayılan Eylemi",
      smallBlindIndex: "Küçük Kör Bahis İndeksi",
      bigBlindIndex: "Büyük Kör Bahis İndeksi",
      dealerPosition: "Dağıtıcı Pozisyonu",
      inProgress: "Devam Ediyor",
      isPasswordProtected: "Şifre Koruması Var mı",
      isActive: "Aktif mi",
      blinds: "Blinds",
      createdAt: "Oluşturulma Tarihi",
      updatedAt: "Güncellenme Tarihi",
    },
    filters: {
      gameName_placeholder: "Oyun Adı",
      gameId_placeholder: "Oyun Kimliği",
    },
  },
};
