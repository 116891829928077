import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { menuConfig } from "./AppLayout.constants";
export var getMenu = function (userInfo, t, lang) {
    return menuConfig.filter(function (item) { return !item.visible || item.visible(userInfo); }).map(function (item) { return getItem(_jsx(Link, { to: "".concat(item.path), children: t("sidebar.menu.".concat(item.key)) }), item.key, item.icon); });
};
function getItem(label, key, icon) {
    return {
        key: key,
        icon: icon,
        label: label,
    };
}
