import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "@atom/ui-kit";
import { useTranslation } from "react-i18next";
var LobbyManagementFilterSpace = function (_a) {
    var onSearch = _a.onSearch, onAddNewData = _a.onAddNewData;
    var form = Form.useForm()[0];
    var t = useTranslation().t;
    var handleSearch = function (values) {
        if (onSearch)
            onSearch(values);
    };
    return (_jsx(Form, { form: form, layout: "inline", onFinish: handleSearch, style: { width: "100%", justifyContent: "space-between" }, children: _jsxs(Row, { children: [_jsx(Col, { sm: 12, children: _jsx(Form.Item, { children: _jsx(Button, { buttonSize: "small", icon: _jsx(ReloadOutlined, {}), htmlType: "submit", children: t("root.refresh_button") }) }) }), _jsx(Col, { sm: 12, children: _jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(PlusOutlined, {}), type: "primary", onClick: onAddNewData, children: t("root.add_button") }) })] }) }));
};
export default LobbyManagementFilterSpace;
