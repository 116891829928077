var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LobbyControllerApiFactory } from "@/apis/lobby-management";
import useApi from "@/hooks/useApi";
import { Select } from "@atom/ui-kit";
import { startCase } from "lodash";
import React, { useEffect } from "react";
var LobbySelect = function (props) {
    var _a;
    var _b = React.useState(""), searchValue = _b[0], setSearchValue = _b[1];
    var _c = useApi({
        asyncFunction: LobbyControllerApiFactory().lobbiesTenantIdGet,
    }), callLobbyManagementId = _c.call, lobbyManagementId = _c.data, lobbyManagementIdLoading = _c.loading;
    var _d = useApi({
        asyncFunction: LobbyControllerApiFactory().lobbiesTenantGet,
    }), callLobbyManagementList = _d.call, lobbyManagementData = _d.data, lobbyManagementLoading = _d.loading;
    useEffect(function () {
        console.log("lobbyManagementData", lobbyManagementData, searchValue.length);
        if (searchValue.length > 0) {
            callLobbyManagementId({
                id: searchValue,
            });
        }
        else {
            console.log("ses");
            callLobbyManagementList({
                tenant: props === null || props === void 0 ? void 0 : props.tenant,
            });
        }
        return function () {
            setSearchValue("");
        };
    }, [searchValue]);
    var prepareData = function () {
        if (searchValue.length > 0) {
            return [lobbyManagementId];
        }
        return lobbyManagementData === null || lobbyManagementData === void 0 ? void 0 : lobbyManagementData.data.items;
    };
    return (_jsx(Select, __assign({ style: { width: "100%" }, loading: lobbyManagementIdLoading || lobbyManagementLoading, showSearch: true, onSearch: setSearchValue }, props, { children: (_a = prepareData()) === null || _a === void 0 ? void 0 : _a.map(function (lobby) { return (_jsx(Select.Option, { value: props.selector ? lobby === null || lobby === void 0 ? void 0 : lobby[props.selector] : lobby === null || lobby === void 0 ? void 0 : lobby.id, children: startCase(lobby === null || lobby === void 0 ? void 0 : lobby.name) }, lobby === null || lobby === void 0 ? void 0 : lobby.name)); }) })));
};
export default LobbySelect;
