var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTableHeight } from "@/hooks/useTableHeight";
import { Table } from "antd";
import React, { useCallback, useRef, useState } from "react";
var InfinityTable = function (_a) {
    var fetchData = _a.fetchData, dataSource = _a.dataSource, hasMore = _a.hasMore, loading = _a.loading, containerStyle = _a.containerStyle, props = __rest(_a, ["fetchData", "dataSource", "hasMore", "loading", "containerStyle"]);
    var _b = useState(dataSource), localData = _b[0], setLocalData = _b[1];
    var contentRef = useRef(null);
    var tableHeight = useTableHeight(contentRef);
    var loadMoreData = useCallback(function () {
        if (loading || !hasMore)
            return;
        fetchData === null || fetchData === void 0 ? void 0 : fetchData();
    }, [loading, hasMore, fetchData]);
    var handleScroll = function (e) {
        var bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if (bottom) {
            loadMoreData();
        }
    };
    React.useEffect(function () {
        setLocalData(dataSource);
    }, [dataSource]);
    return (_jsx("div", { ref: contentRef, style: __assign({ height: "70vh" }, containerStyle), children: _jsx(Table, __assign({ onScroll: handleScroll, dataSource: localData, size: "small", scroll: { x: 1200, y: tableHeight }, pagination: false, loading: loading }, props)) }));
};
export default InfinityTable;
