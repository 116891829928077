// hooks/useAuthInterceptor.ts
import { getUser } from "@/utils/storage";
import { message } from "antd"; // Ant Design'dan message özelliğini kullanıyoruz
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function useAuthInterceptor() {
    var navigate = useNavigate();
    useEffect(function () {
        var user = getUser();
        if (!user) {
            navigate("/sign-in");
            return;
        }
        var interceptor = axios.interceptors.response.use(function (response) { return response; }, function (error) {
            var _a;
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
                navigate("/sign-in");
                message.error("Session expired, please login again.");
            }
            return Promise.reject(error);
        });
        // Clean up interceptor on component unmount
        return function () {
            axios.interceptors.response.eject(interceptor);
        };
    }, [navigate]);
}
