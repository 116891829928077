/* tslint:disable */
/* eslint-disable */
/**
 * PokerWise Game Management
 * API for managing PokerWise games.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * GameControllerApi - axios parameter creator
 * @export
 */
export var GameControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Delete a game.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdDelete: function (tenant_1, id_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, id_1], args_1, true), void 0, function (tenant, id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('gamesByidTenantIdDelete', 'tenant', tenant);
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('gamesByidTenantIdDelete', 'id', id);
                            localVarPath = "/games/byid/{tenant}/{id}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a specific game by ID.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdGet: function (tenant_1, id_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, id_1], args_1, true), void 0, function (tenant, id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('gamesByidTenantIdGet', 'tenant', tenant);
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('gamesByidTenantIdGet', 'id', id);
                            localVarPath = "/games/byid/{tenant}/{id}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing game.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the game.
         * @param {GamesByidTenantIdPutRequest} gamesByidTenantIdPutRequest A JSON object for updating the poker game information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdPut: function (tenant_1, id_1, gamesByidTenantIdPutRequest_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, id_1, gamesByidTenantIdPutRequest_1], args_1, true), void 0, function (tenant, id, gamesByidTenantIdPutRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('gamesByidTenantIdPut', 'tenant', tenant);
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('gamesByidTenantIdPut', 'id', id);
                            // verify required parameter 'gamesByidTenantIdPutRequest' is not null or undefined
                            assertParamExists('gamesByidTenantIdPut', 'gamesByidTenantIdPutRequest', gamesByidTenantIdPutRequest);
                            localVarPath = "/games/byid/{tenant}/{id}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(gamesByidTenantIdPutRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a game by name.
         * @param {string} tenant The tenant identifier.
         * @param {string} name The name of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesBynameTenantNameGet: function (tenant_1, name_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, name_1], args_1, true), void 0, function (tenant, name, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('gamesBynameTenantNameGet', 'tenant', tenant);
                            // verify required parameter 'name' is not null or undefined
                            assertParamExists('gamesBynameTenantNameGet', 'name', name);
                            localVarPath = "/games/byname/{tenant}/{name}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("name", "}"), encodeURIComponent(String(name)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all games with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {number} [limit] The maximum number of games to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesGet: function (tenant_1, limit_1, lastKey_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, limit_1, lastKey_1], args_1, true), void 0, function (tenant, limit, lastKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('gamesGet', 'tenant', tenant);
                            localVarPath = "/games"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (lastKey !== undefined) {
                                localVarQueryParameter['lastKey'] = lastKey;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a new game.
         * @param {GamesPostRequest} gamesPostRequest A JSON object containing the poker game information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesPost: function (gamesPostRequest_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([gamesPostRequest_1], args_1, true), void 0, function (gamesPostRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'gamesPostRequest' is not null or undefined
                            assertParamExists('gamesPost', 'gamesPostRequest', gamesPostRequest);
                            localVarPath = "/games";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(gamesPostRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all games of a tenant with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {number} [limit] The maximum number of games to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesTenantGet: function (tenant_1, limit_1, lastKey_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, limit_1, lastKey_1], args_1, true), void 0, function (tenant, limit, lastKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('gamesTenantGet', 'tenant', tenant);
                            localVarPath = "/games/{tenant}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (lastKey !== undefined) {
                                localVarQueryParameter['lastKey'] = lastKey;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * GameControllerApi - functional programming interface
 * @export
 */
export var GameControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = GameControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Delete a game.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdDelete: function (tenant, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gamesByidTenantIdDelete(tenant, id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['GameControllerApi.gamesByidTenantIdDelete']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a specific game by ID.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdGet: function (tenant, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gamesByidTenantIdGet(tenant, id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['GameControllerApi.gamesByidTenantIdGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing game.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the game.
         * @param {GamesByidTenantIdPutRequest} gamesByidTenantIdPutRequest A JSON object for updating the poker game information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdPut: function (tenant, id, gamesByidTenantIdPutRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gamesByidTenantIdPut(tenant, id, gamesByidTenantIdPutRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['GameControllerApi.gamesByidTenantIdPut']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a game by name.
         * @param {string} tenant The tenant identifier.
         * @param {string} name The name of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesBynameTenantNameGet: function (tenant, name, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gamesBynameTenantNameGet(tenant, name, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['GameControllerApi.gamesBynameTenantNameGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all games with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {number} [limit] The maximum number of games to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesGet: function (tenant, limit, lastKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gamesGet(tenant, limit, lastKey, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['GameControllerApi.gamesGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a new game.
         * @param {GamesPostRequest} gamesPostRequest A JSON object containing the poker game information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesPost: function (gamesPostRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gamesPost(gamesPostRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['GameControllerApi.gamesPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all games of a tenant with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {number} [limit] The maximum number of games to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesTenantGet: function (tenant, limit, lastKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.gamesTenantGet(tenant, limit, lastKey, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['GameControllerApi.gamesTenantGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
/**
 * GameControllerApi - factory interface
 * @export
 */
export var GameControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = GameControllerApiFp(configuration);
    return {
        /**
         *
         * @summary Delete a game.
         * @param {GameControllerApiGamesByidTenantIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdDelete: function (requestParameters, options) {
            return localVarFp.gamesByidTenantIdDelete(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get details of a specific game by ID.
         * @param {GameControllerApiGamesByidTenantIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdGet: function (requestParameters, options) {
            return localVarFp.gamesByidTenantIdGet(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update an existing game.
         * @param {GameControllerApiGamesByidTenantIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesByidTenantIdPut: function (requestParameters, options) {
            return localVarFp.gamesByidTenantIdPut(requestParameters.tenant, requestParameters.id, requestParameters.gamesByidTenantIdPutRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get details of a game by name.
         * @param {GameControllerApiGamesBynameTenantNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesBynameTenantNameGet: function (requestParameters, options) {
            return localVarFp.gamesBynameTenantNameGet(requestParameters.tenant, requestParameters.name, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get a list of all games with pagination.
         * @param {GameControllerApiGamesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesGet: function (requestParameters, options) {
            return localVarFp.gamesGet(requestParameters.tenant, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create a new game.
         * @param {GameControllerApiGamesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesPost: function (requestParameters, options) {
            return localVarFp.gamesPost(requestParameters.gamesPostRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get a list of all games of a tenant with pagination.
         * @param {GameControllerApiGamesTenantGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gamesTenantGet: function (requestParameters, options) {
            return localVarFp.gamesTenantGet(requestParameters.tenant, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * GameControllerApi - object-oriented interface
 * @export
 * @class GameControllerApi
 * @extends {BaseAPI}
 */
var GameControllerApi = /** @class */ (function (_super) {
    __extends(GameControllerApi, _super);
    function GameControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete a game.
     * @param {GameControllerApiGamesByidTenantIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameControllerApi
     */
    GameControllerApi.prototype.gamesByidTenantIdDelete = function (requestParameters, options) {
        var _this = this;
        return GameControllerApiFp(this.configuration).gamesByidTenantIdDelete(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get details of a specific game by ID.
     * @param {GameControllerApiGamesByidTenantIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameControllerApi
     */
    GameControllerApi.prototype.gamesByidTenantIdGet = function (requestParameters, options) {
        var _this = this;
        return GameControllerApiFp(this.configuration).gamesByidTenantIdGet(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update an existing game.
     * @param {GameControllerApiGamesByidTenantIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameControllerApi
     */
    GameControllerApi.prototype.gamesByidTenantIdPut = function (requestParameters, options) {
        var _this = this;
        return GameControllerApiFp(this.configuration).gamesByidTenantIdPut(requestParameters.tenant, requestParameters.id, requestParameters.gamesByidTenantIdPutRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get details of a game by name.
     * @param {GameControllerApiGamesBynameTenantNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameControllerApi
     */
    GameControllerApi.prototype.gamesBynameTenantNameGet = function (requestParameters, options) {
        var _this = this;
        return GameControllerApiFp(this.configuration).gamesBynameTenantNameGet(requestParameters.tenant, requestParameters.name, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get a list of all games with pagination.
     * @param {GameControllerApiGamesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameControllerApi
     */
    GameControllerApi.prototype.gamesGet = function (requestParameters, options) {
        var _this = this;
        return GameControllerApiFp(this.configuration).gamesGet(requestParameters.tenant, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create a new game.
     * @param {GameControllerApiGamesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameControllerApi
     */
    GameControllerApi.prototype.gamesPost = function (requestParameters, options) {
        var _this = this;
        return GameControllerApiFp(this.configuration).gamesPost(requestParameters.gamesPostRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get a list of all games of a tenant with pagination.
     * @param {GameControllerApiGamesTenantGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameControllerApi
     */
    GameControllerApi.prototype.gamesTenantGet = function (requestParameters, options) {
        var _this = this;
        return GameControllerApiFp(this.configuration).gamesTenantGet(requestParameters.tenant, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return GameControllerApi;
}(BaseAPI));
export { GameControllerApi };
