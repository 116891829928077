export const signIn = {
  signIn: {
    root: {
      title: "Giriş Yap",
      logoAltText: "Tenant Logosu",
      errorMessage: "Giriş başarısız oldu. Lütfen bilgilerinizi kontrol edin.",
    },
    loginForm: {
      usernameErrorMessage: "Geçerli bir kullanıcı adı değil!",
      usernamePlaceholder: "Kullanıcı Adı",
      passwordErrorMessage: "Lütfen şifrenizi girin!",
      passwordPlaceholder: "Şifre",
      signInButton: "Giriş Yap",
    },
    tenantForm: {
      tenantErrorMessage: "Geçerli bir tenant değil!",
      tenantPlaceholder: "Tenant Seçin",
      nextButton: "İleri",
    },
  },
};
