import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CopyOutlined } from "@ant-design/icons";
import { Popover, Tooltip, Typography } from "antd";
var ID_VIEW_MAX_LENGTH = 15;
export var IdView = function (_a) {
    var id = _a.id;
    var shortId = (id === null || id === void 0 ? void 0 : id.length) > ID_VIEW_MAX_LENGTH ? "".concat(id === null || id === void 0 ? void 0 : id.slice(0, ID_VIEW_MAX_LENGTH), "...") : id;
    var handleCopy = function () {
        navigator.clipboard.writeText(id); // Tam ID'yi kopyala
    };
    return (_jsx(Popover, { content: id, trigger: "hover", children: _jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [_jsx(Typography.Text, { children: shortId }), _jsx(Tooltip, { title: "Copy ID", children: _jsx(CopyOutlined, { onClick: handleCopy, style: { marginLeft: 8, cursor: "pointer", color: "#1890ff" } }) })] }) }));
};
export default IdView;
