var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LobbyControllerApiFactory } from "@/apis/lobby-management";
import { TableControllerApiFactory } from "@/apis/table-management";
import InfinityTable from "@/components/Infinitytable";
import { useApi } from "@/hooks/useApi";
import { Card, Divider, message, Modal } from "@atom/ui-kit";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { columns } from "./TableManagement.columns";
import TableManagementDetail from "./TableManagement.detail";
import TableManagementFilterSpace from "./TableManagement.filters";
var DEFAULT_PAGE_SIZE = 50;
var TableManagement = function () {
    var navigate = useNavigate();
    var t = useTranslation().t;
    var contentRef = useRef(null);
    var _a = useState({
        open: false,
        recordData: [],
    }), draweState = _a[0], setDrawerState = _a[1];
    var _b = useState({
        tenant: "wisegaming",
        limit: DEFAULT_PAGE_SIZE,
        requestType: "all",
    }), reqParams = _b[0], setReqParams = _b[1];
    var _c = useState([]), tableData = _c[0], setTableData = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    // Dinamik API çağrısı yapan fonksiyon
    var fetchTableData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 11, 12, 13]);
                    setLoading(true);
                    response = void 0;
                    _a = reqParams.requestType;
                    switch (_a) {
                        case "all": return [3 /*break*/, 1];
                        case "tableId": return [3 /*break*/, 3];
                        case "tableName": return [3 /*break*/, 5];
                        case "type": return [3 /*break*/, 7];
                    }
                    return [3 /*break*/, 9];
                case 1: return [4 /*yield*/, TableControllerApiFactory().tablesTenantGet(reqParams)];
                case 2:
                    response = (_b.sent());
                    setTableData(response.data.data.tables);
                    return [3 /*break*/, 10];
                case 3: return [4 /*yield*/, TableControllerApiFactory().tablesByidTenantIdGet({
                        id: reqParams.tableId,
                        tenant: reqParams.tenant,
                    })];
                case 4:
                    response = (_b.sent());
                    setTableData([response.data.data]);
                    return [3 /*break*/, 10];
                case 5: return [4 /*yield*/, TableControllerApiFactory().tablesBynameTenantNameGet({
                        name: reqParams.tableName,
                        tenant: reqParams.tenant,
                    })];
                case 6:
                    response = (_b.sent());
                    setTableData([response.data.data]);
                    return [3 /*break*/, 10];
                case 7: return [4 /*yield*/, TableControllerApiFactory().tablesBytypeTenantTypeGet({
                        type: reqParams.type,
                        tenant: reqParams.tenant,
                    })];
                case 8:
                    response = (_b.sent());
                    setTableData(response.data.data);
                    return [3 /*break*/, 10];
                case 9:
                    message.error("Invalid request type");
                    return [2 /*return*/];
                case 10: return [3 /*break*/, 13];
                case 11:
                    error_1 = _b.sent();
                    message.error("Failed to fetch table data");
                    return [3 /*break*/, 13];
                case 12:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 13: return [2 /*return*/];
            }
        });
    }); };
    // Delete TableManagement data
    var _e = useApi({
        asyncFunction: TableControllerApiFactory().tablesByidTenantIdDelete, // set async function here
        successCallback: function (response) {
            message.success(t("root.delete_success"));
            fetchTableData(); // Silindikten sonra listeyi yenile
        },
        errorCallback: function (error) {
            message.error(t("root.delete_error"));
        },
    }), callDeleteTableManagement = _e.call, deleteTableManagementLoading = _e.loading;
    var _f = useApi({
        asyncFunction: LobbyControllerApiFactory().lobbiesTenantGet,
    }), callLobbyManagementList = _f.call, lobbyManagementData = _f.data;
    var handleDelete = function (key) {
        Modal.confirm({
            title: t("root.delete_confirmation_title"),
            content: t("root.delete_confirmation_message"),
            okText: t("root.confirm"),
            okType: "danger",
            cancelText: t("root.cancel"),
            onOk: function () {
                callDeleteTableManagement({
                    id: key,
                    tenant: "wisegaming",
                });
            },
        });
    };
    var handleEdit = function (key, tenant) {
        navigate("/table-management/".concat(key, "?tenant=").concat(tenant)); // Düzenlemek için yönlendirme
    };
    var handleAdd = function () {
        navigate("/table-management/add"); // Yeni veri eklemek için yönlendirme
    };
    var handleOpenDetail = function (recordData) {
        setDrawerState({ open: true, recordData: recordData });
    };
    var handleSearch = function (values) {
        setReqParams(__assign(__assign(__assign({}, reqParams), values), { requestType: values.type ? "type" : values.tableId ? "tableId" : values.tableName ? "tableName" : "all" }));
    };
    useEffect(function () {
        fetchTableData();
        callLobbyManagementList({
            tenant: "wisegaming",
        });
    }, [reqParams]);
    return (_jsxs(Card, { style: {
            padding: "20px 0",
            height: "80vh",
        }, children: [_jsx(TableManagementFilterSpace, { onAddNewData: handleAdd, onSearch: handleSearch }), _jsx(Divider, {}), _jsx(InfinityTable, { columns: columns(handleOpenDetail, t, lobbyManagementData === null || lobbyManagementData === void 0 ? void 0 : lobbyManagementData.data), dataSource: tableData || [], loading: loading || deleteTableManagementLoading }), _jsx(TableManagementDetail, { open: draweState.open, onClose: function () { return setDrawerState(__assign(__assign({}, draweState), { open: false, recordData: [] })); }, recordData: draweState.recordData, lobbyData: lobbyManagementData === null || lobbyManagementData === void 0 ? void 0 : lobbyManagementData.data, handleDelete: handleDelete, handleEdit: handleEdit, t: t })] }));
};
export default TableManagement;
