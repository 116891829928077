var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var TOGGLE_BUTTON = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 12px;\n  height: 64px;\n  width: 64px;\n  background-color: ", ";\n\n  .arrow-left {\n    transform: rotate(90deg);\n    transition: transform 0.7s;\n  }\n\n  .arrow-right {\n    transform: rotate(270deg);\n    transition: transform 0.7s;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 12px;\n  height: 64px;\n  width: 64px;\n  background-color: ", ";\n\n  .arrow-left {\n    transform: rotate(90deg);\n    transition: transform 0.7s;\n  }\n\n  .arrow-right {\n    transform: rotate(270deg);\n    transition: transform 0.7s;\n  }\n"])), function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = theme === null || theme === void 0 ? void 0 : theme.colors) === null || _b === void 0 ? void 0 : _b.GrayscaleInput;
});
TOGGLE_BUTTON.displayName = "TOGGLE_BUTTON";
var templateObject_1;
