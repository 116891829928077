import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Form, FormItem, Input, InputPassword } from "@atom/ui-kit";
import { useTranslation } from "react-i18next";
var LoginForm = function (_a) {
    var handleSignIn = _a.handleSignIn;
    var t = useTranslation().t;
    return (_jsxs(Form, { onFinish: handleSignIn, children: [_jsx(FormItem, { name: "username", rules: [
                    {
                        required: true,
                        type: "string",
                        message: t("signIn.loginForm.usernameErrorMessage"),
                    },
                ], children: _jsx(Input, { variant: "borderless", placeholder: t("signIn.loginForm.usernamePlaceholder") }) }), _jsx(FormItem, { name: "password", rules: [
                    {
                        required: true,
                        message: t("signIn.loginForm.passwordErrorMessage"),
                    },
                ], children: _jsx(InputPassword, { variant: "borderless", placeholder: t("signIn.loginForm.passwordPlaceholder") }) }), _jsx(FormItem, { children: _jsx(Button, { block: true, buttonType: "primary", loading: false, htmlType: "submit", children: t("signIn.loginForm.signInButton") }) })] }));
};
export default LoginForm;
