import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Popover, Tag } from "antd";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
var DetailPopover = function (_a) {
    var data = _a.data;
    var t = useTranslation().t;
    return (_jsx(Popover, { content: _jsx("div", { style: {
                width: "400px",
                maxHeight: "400px",
                overflow: "auto",
            }, children: _jsx(ReactJson, { name: t("root.details"), displayDataTypes: false, displayObjectSize: false, src: data || {} }) }), trigger: "hover", children: _jsxs(Tag, { color: "blue", children: [" ", t("root.details"), " "] }) }));
};
export default DetailPopover;
