/* tslint:disable */
/* eslint-disable */
/**
 * PokerWise Table Management
 * API for managing PokerWise tables.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export var TablesPostRequestCategoryEnum = {
    Poker: 'poker'
};
export var TablesPostRequestTypeEnum = {
    Texasholdem: 'texasholdem',
    Turkishpoker: 'turkishpoker',
    Omeha4: 'omeha4',
    Omeha5: 'omeha5'
};
export var TablesPostRequestLimitTypeEnum = {
    Nolimit: 'nolimit',
    Potlimit: 'potlimit',
    Fixedlimit: 'fixedlimit',
    Spreadlimit: 'spreadlimit'
};
export var TablesPostRequestTimeoutDefaultActionEnum = {
    Fold: 'Fold',
    Call: 'Call'
};
