import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlayerControllerApiFactory } from "@/apis/player-management";
import GoBack from "@/components/GoBack";
import CurrencySelect from "@/components/Selects/CurrencySelect";
import { useApi } from "@/hooks/useApi";
import { Button, Card, Col, Form, Input, message, Row } from "@atom/ui-kit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
var EditPlayerManagement = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var id = useParams().id;
    var _a = useApi({
        asyncFunction: PlayerControllerApiFactory().playersBynameUsernameGet,
        successCallback: function (response) {
            form.setFieldsValue(response.data.data.player);
        },
        errorCallback: function (error) {
            message.error("Error fetching data");
        },
    }), fetchPlayerManagementById = _a.call, playerManagementData = _a.data;
    var _b = useApi({
        asyncFunction: PlayerControllerApiFactory().playersBynameUsernamePut,
        successCallback: function (response) {
            message.success("PlayerManagement edited successfully");
            navigate("/player-management");
        },
        errorCallback: function (error) {
            message.error("Error editing PlayerManagement");
        },
    }), callEditPlayerManagement = _b.call, loading = _b.loading;
    var handleFinish = function (values) {
        callEditPlayerManagement({
            username: playerManagementData === null || playerManagementData === void 0 ? void 0 : playerManagementData.username,
            playerUpdate: values,
        });
    };
    useEffect(function () {
        console.log(id);
        if (id)
            fetchPlayerManagementById({ username: id });
    }, [id]);
    console.log(playerManagementData);
    return (_jsxs(Card, { style: { padding: "20px 0" }, children: [_jsx(GoBack, { title: t("playerManagement.edit.title") }), _jsxs(Form, { form: form, layout: "vertical", onFinish: handleFinish, children: [_jsxs(Row, { gutter: 16, children: [_jsx(Col, { xs: 12, sm: 12, md: 8, lg: 6, children: _jsx(Form.Item, { label: t("playerManagement.edit.nickname"), name: "nickname", children: _jsx(Input, { size: "small", variant: "borderless" }) }) }), _jsx(Col, { xs: 12, sm: 12, md: 8, lg: 6, children: _jsx(Form.Item, { label: t("playerManagement.edit.gender"), name: "gender", children: _jsx(Input, { size: "small", variant: "borderless" }) }) }), _jsx(Col, { xs: 12, sm: 12, md: 8, lg: 6, children: _jsx(Form.Item, { label: t("playerManagement.edit.currency"), name: "currency", children: _jsx(CurrencySelect, {}) }) }), _jsx(Col, { xs: 12, sm: 12, md: 8, lg: 6, children: _jsx(Form.Item, { label: t("playerManagement.edit.block_status"), name: "block_status", children: _jsx(Input, { size: "small", variant: "borderless" }) }) }), _jsx(Col, { xs: 12, sm: 12, md: 8, lg: 6, children: _jsx(Form.Item, { label: t("playerManagement.edit.language"), name: "language", children: _jsx(Input, { size: "small", variant: "borderless" }) }) }), _jsx(Col, { xs: 12, sm: 12, md: 8, lg: 6, children: _jsx(Form.Item, { label: t("playerManagement.edit.locale"), name: "locale", children: _jsx(Input, { size: "small", variant: "borderless" }) }) })] }), _jsx(Row, { children: _jsx(Col, { span: 12, children: _jsx(Button, { block: true, buttonType: "primary", htmlType: "submit", loading: loading, children: t("root.save_button") }) }) })] })] }));
};
export default EditPlayerManagement;
