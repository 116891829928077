var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  border-radius: 20px;\n  padding-bottom: 20px;\n  width: fit-content;\n  cursor: pointer;\n  background-color: transparent;\n  &:hover {\n    opacity: 0.7;\n  }\n  h1 {\n    font-size: 24px;\n    font-weight: 400;\n    line-height: 32px;\n    letter-spacing: 1px;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  border-radius: 20px;\n  padding-bottom: 20px;\n  width: fit-content;\n  cursor: pointer;\n  background-color: transparent;\n  &:hover {\n    opacity: 0.7;\n  }\n  h1 {\n    font-size: 24px;\n    font-weight: 400;\n    line-height: 32px;\n    letter-spacing: 1px;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.GrayscaleBody;
});
var templateObject_1;
