import { jsx as _jsx } from "react/jsx-runtime";
import IdViewer from "@/components/IdViewer";
import { MoreOutlined } from "@ant-design/icons";
import { Button } from "@atom/ui-kit";
import { Dropdown, Menu } from "antd";
export var columns = function (handleEdit, t) { return [
    { title: t("playerManagement.columns.tenant"), dataIndex: "tenant", key: "tenant", width: 120 },
    { title: t("playerManagement.columns.username"), dataIndex: "username", key: "username", width: 120 },
    { title: t("playerManagement.columns.block_status"), dataIndex: "block_status", key: "block_status", width: 120 },
    { title: t("playerManagement.columns.currency"), dataIndex: "currency", key: "currency", width: 120 },
    { title: t("playerManagement.columns.game_id"), dataIndex: "game_id", key: "game_id", width: 120, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("playerManagement.columns.gender"), dataIndex: "gender", key: "gender", width: 120 },
    { title: t("playerManagement.columns.language"), dataIndex: "language", key: "language", width: 120 },
    { title: t("playerManagement.columns.lobby_id"), dataIndex: "lobby_id", key: "lobby_id", width: 120, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("playerManagement.columns.locale"), dataIndex: "locale", key: "locale", width: 120 },
    { title: t("playerManagement.columns.nickname"), dataIndex: "nickname", key: "nickname", width: 120 },
    { title: t("playerManagement.columns.session_id"), dataIndex: "session_id", key: "session_id", width: 120, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("playerManagement.columns.table_id"), dataIndex: "table_id", key: "table_id", width: 120, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("playerManagement.columns.user_id"), dataIndex: "user_id", key: "user_id", width: 120, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 80,
        fixed: "right",
        render: function (_, record) {
            var menu = (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: function () { return handleEdit(record.username, record.tenant); }, children: t("root.edit_button") }, "edit") }));
            return (_jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(MoreOutlined, {}), children: t("root.actions_button") }) }));
        },
    },
]; };
