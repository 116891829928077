import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LogoutIcon from "@/components/Icons/LogoutIcon";
import { clearUser } from "@/utils/storage";
import { useThemeContext } from "@atom/ui-kit";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export var AppFooter = function () {
    var config = useThemeContext();
    var navigate = useNavigate();
    var t = useTranslation().t;
    var handleLogout = function () {
        clearUser();
        navigate("/sign-in");
    };
    return (_jsxs("div", { style: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px 24px",
            gap: 16,
            cursor: "pointer",
        }, onClick: handleLogout, children: [_jsx(LogoutIcon, {}), _jsx(Typography.Text, { style: {
                    color: config.theme.colors.GrayscaleBackground,
                    cursor: "pointer",
                }, children: t("sidebar.logout") })] }));
};
export default AppFooter;
