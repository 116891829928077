"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DefaultApiFactory } from "@/apis/auth-management";
import Logo from "@/assets/images/logos/wiselogo.svg";
import useApi from "@/hooks/useApi";
import { setUser } from "@/utils/storage";
import { message, theme, Typography } from "@atom/ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoginForm from "./_components/LoginForm";
import TenantForm from "./_components/TenantForm";
import { BannerWrapper, LoginWrapper, SignInContainer, StyledForm, TenantLogo } from "./SignIn.style";
var SignIn = function () {
    var t = useTranslation().t;
    var _a = useState("sign-in"), step = _a[0], setStep = _a[1];
    var _b = useState({
        username: "",
        password: "",
        tenant: "",
    }), formValues = _b[0], setFormValues = _b[1];
    var navigate = useNavigate();
    var _c = useApi({
        asyncFunction: DefaultApiFactory().backofficeusersLoginPost,
        successCallback: function (res, req) {
            setUser({
                username: req.backofficeusersLoginPostRequest.username,
                tenant: req.backofficeusersLoginPostRequest.tenant,
                sessionId: res.data.sessionId,
            });
            navigate("/");
        },
        errorCallback: function (err) {
            console.log(err);
            message.error(t("signIn.root.errorMessage"));
        },
    }), call = _c.call, loading = _c.loading, error = _c.error;
    var handleSignIn = function (values) {
        setFormValues(__assign(__assign({}, formValues), values));
        setStep("tenant-selection");
    };
    var handleTenantSelection = function (values) {
        setFormValues(__assign(__assign({}, formValues), values));
        call({
            backofficeusersLoginPostRequest: {
                username: formValues.username,
                password: formValues.password,
                tenant: values.tenant,
                is_raw_password: true,
            },
        });
    };
    useEffect(function () {
        var _a, _b, _c, _d;
        if (error) {
            message.error((_d = (_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : error === null || error === void 0 ? void 0 : error.message) !== null && _d !== void 0 ? _d : "Request not handled");
        }
    }, [error]);
    return (_jsxs(SignInContainer, { children: [_jsx(BannerWrapper, { children: _jsx(TenantLogo, { children: _jsx("img", { src: Logo, width: 308, height: 85, alt: t("signIn.root.logoAltText") }) }) }), _jsx(LoginWrapper, { children: _jsxs(StyledForm, { children: [_jsx(Typography.Title, { color: theme.colors.GrayscaleHeader, children: t("signIn.root.title") }), step === "sign-in" ? _jsx(LoginForm, { handleSignIn: handleSignIn }) : _jsx(_Fragment, {}), step === "tenant-selection" ? _jsx(TenantForm, { handleTenantSelection: handleTenantSelection, loading: loading }) : _jsx(_Fragment, {})] }) })] }));
};
export default SignIn;
