// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

html,
body {
  background: #fff;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  height: 100vh;
}

.ant-form-item .ant-form-item-label > label {
  color: var(--Alias-Grayscale-Header-Weak, #262338);
  font-feature-settings: "liga" off, "clig" off;
  /* Desktop/Link Small - Tight */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.75px;
}`, "",{"version":3,"sources":["webpack://./src/global.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,UAAA;EACA,SAAA;EACA,iCAAA;AACF;;AAEA;;EAEE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iCAAA;EACA,aAAA;AACF;;AAEA;EACE,kDAAA;EACA,6CAAA;EAEA,+BAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AAAF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
