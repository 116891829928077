"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRef, useState } from "react";
export var useApi = function (_a) {
    var asyncFunction = _a.asyncFunction, successCallback = _a.successCallback, errorCallback = _a.errorCallback;
    var counter = useRef(0);
    var _b = useState({
        loading: false,
        data: undefined,
        error: undefined,
    }), value = _b[0], setValue = _b[1];
    var call = function (request, additionalProps) {
        counter.current++;
        setValue(__assign(__assign({}, value), { loading: true, error: undefined }));
        return asyncFunction(request)
            .then(function (response) {
            if (response.isAxiosError) {
                if (errorCallback)
                    errorCallback(response, request, additionalProps);
                setValue(__assign(__assign({}, value), { loading: false, data: undefined, error: response }));
            }
            else {
                if (successCallback)
                    successCallback(response, request, additionalProps);
                setValue(__assign(__assign({}, value), { loading: false, data: response.data }));
            }
        })
            .catch(function (error) {
            setValue(__assign(__assign({}, value), { loading: false, data: undefined, error: error }));
        });
    };
    var setData = function (data) {
        setValue(__assign(__assign({}, value), { data: data, loading: false, error: undefined }));
    };
    var clear = function () {
        setValue({ loading: false, data: undefined, error: undefined });
    };
    return __assign(__assign({}, value), { call: call, clear: clear, setData: setData });
};
export default useApi;
