import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IdViewer from "@/components/IdViewer";
import { UserRoles } from "@/constants/role";
import { MoreOutlined } from "@ant-design/icons";
import { Button, Tag, Typography } from "@atom/ui-kit";
import { Dropdown, Menu } from "antd";
export var columns = function (handleEdit, handleDelete, t) { return [
    { title: t("userManagement.columns.id"), dataIndex: "id", key: "id", width: 50, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("userManagement.columns.tenant"), dataIndex: "tenant", key: "tenant", width: 80, render: function (text) { return _jsx(Typography.Text, { strong: true, children: text }); } },
    { title: t("userManagement.columns.username"), dataIndex: "username", key: "username", width: 80 },
    { title: t("userManagement.columns.email"), dataIndex: "email", key: "email", width: 80 },
    { title: t("userManagement.columns.role"), dataIndex: "role", key: "role", width: 100, render: function (text) { return _jsx(Tag, { color: text === UserRoles.Godmin ? "red" : text === UserRoles.Lobadmin ? "magenta" : "blue", children: text }); } },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 50,
        fixed: "right",
        render: function (_, record) {
            var menu = (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: function () { return handleEdit(record.id, record.tenant); }, children: t("root.edit_button") }, "edit"), _jsx(Menu.Item, { onClick: function () { return handleDelete(record.username); }, children: t("root.delete_button") }, "delete")] }));
            return (_jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(MoreOutlined, {}), children: t("root.actions_button") }) }));
        },
    },
]; };
