export const lobbyManagement = {
  lobbyManagement: {
    edit: {
      title: "Edit Lobby",
      name: "Name",
      tenant: "Tenant",
      lobbyUrl: "Lobby URL",
      userDetailsUrl: "User Details URL",
      transactionUrl: "Transaction URL",
      getBalanceUrl: "Get Balance URL",
      rakeInfoUrl: "Rake Info URL",
      contactEmail: "Contact Email",
      maxNumofTables: "Max Number of Tables",
      enabledGameTypes: "Enabled Game Types",
      skincode: "Skin Code",
      note: "Note",
    },
    add: {
      title: "Add Lobby",
      name: "Name",
      name_placeholder: "Enter Name",
      name_required: "Please enter Name",
      tenant: "Tenant",
      tenant_placeholder: "Enter Tenant",
      tenant_required: "Please enter Tenant",
      lobbyUrl: "Lobby URL",
      lobbyUrl_placeholder: "Enter Lobby URL",
      lobbyUrl_required: "Please enter Lobby URL",
      userDetailsUrl: "User Details URL",
      userDetailsUrl_placeholder: "Enter User Details URL",
      transactionUrl: "Transaction URL",
      transactionUrl_placeholder: "Enter Transaction URL",
      getBalanceUrl: "Get Balance URL",
      getBalanceUrl_placeholder: "Enter Get Balance URL",
      rakeInfoUrl: "Rake Info URL",
      rakeInfoUrl_placeholder: "Enter Rake Info URL",
      contactEmail: "Contact Email",
      contactEmail_placeholder: "Enter Contact Email",
      contactEmail_required: "Please enter Contact Email",
      maxNumofTables: "Max Number of Tables",
      maxNumofTables_placeholder: "Enter Max Number of Tables",
      maxNumofTables_required: "Please enter Max Number of Tables",
      enabledGameTypes: "Enabled Game Types",
      enabledGameTypes_placeholder: "Enter Enabled Game Types",
      enabledGameTypes_required: "Please enter Enabled Game Types",
      skincode: "Skin Code",
      skincode_placeholder: "Enter Skin Code",
      skincode_required: "Please enter Skin Code",
      note: "Note",
      note_placeholder: "Enter Note",
      success: "LobbyManagement added successfully",
      error: "Error adding LobbyManagement",
    },
    columns: {
      id: "ID Viewer",
      tenant: "Tenant",
      name: "Name",
      enabledGameTypes: "Enabled Game Types",
      tables: "Tables",
      maxNumofTables: "Max Number of Tables",
      skincode: "Skin Code",
      lobbyUrl: "Lobby URL",
      userDetailsUrl: "User Details URL",
      transactionUrl: "Transaction URL",
      getBalanceUrl: "Get Balance URL",
      rakeInfoUrl: "Rake Info URL",
      createdAt: "Created At",
      updatedAt: "Updated At",
    },
    filters: {
      refresh_button: "Refresh",
      add_button: "Add LobbyManagement",
    },
  },
};
