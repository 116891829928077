export const tenantManagement = {
  tenantManagement: {
    edit: {
      title: "Tenantı Düzenle",
      name: "Ad",
      name_required: "Lütfen ad girin",
      id: "Kimlik",
      baseUrl: "Temel URL",
      hmacSecret: "HMAC Gizli Anahtar",
      whitelistedIPs: "Beyaz Listeye Alınmış IP'ler",
      contactEmail: "İletişim E-posta",
      maxNumofTables: "Maksimum Masa Sayısı",
      enabledGameTypes: "Etkin Oyun Türleri",
    },
    add: {
      title: "Tenant Ekle",
      name: "Ad",
      name_placeholder: "Ad Girin",
      name_required: "Lütfen ad girin",
      baseUrl: "Temel URL",
      baseUrl_placeholder: "Temel URL Girin",
      baseUrl_required: "Lütfen temel URL girin",
      hmacSecret: "HMAC Gizli Anahtar",
      hmacSecret_placeholder: "HMAC Gizli Anahtarını Girin",
      hmacSecret_required: "Lütfen HMAC gizli anahtarını girin",
      whitelistedIPs: "Beyaz Listeye Alınmış IP'ler",
      whitelistedIPs_placeholder: "Beyaz Listeye Alınmış IP'leri Girin",
      whitelistedIPs_required: "Lütfen beyaz listeye alınmış IP'leri girin",
      contactEmail: "İletişim E-posta",
      contactEmail_placeholder: "İletişim E-postasını Girin",
      contactEmail_required: "Lütfen iletişim e-postasını girin",
      maxNumofTables: "Maksimum Masa Sayısı",
      maxNumofTables_placeholder: "Maksimum Masa Sayısını Girin",
      maxNumofTables_required: "Lütfen maksimum masa sayısını girin",
      enabledGameTypes: "Etkin Oyun Türleri",
      enabledGameTypes_placeholder: "Etkin Oyun Türlerini Girin",
      enabledGameTypes_required: "Lütfen etkin oyun türlerini girin",
      note: "Not",
      note_placeholder: "Not Girin",
      success: "Tenant başarıyla eklendi",
      error: "Tenant eklenirken hata oluştu",
    },
    columns: {
      id: "Kimlik Görüntüleyici",
      createdAt: "Oluşturulma Tarihi",
      name: "Ad",
      baseUrl: "Temel URL",
      whitelistedIPs: "Beyaz Listeye Alınmış IP'ler",
      enabledGameTypes: "Etkin Oyun Türleri",
      hmacSecret: "HMAC Gizli Anahtar",
      contactEmail: "İletişim E-posta",
    },
  },
};
