export var languageData = [
    {
        languageId: "english",
        locale: "en",
        name: "English",
        icon: "en",
    },
    {
        languageId: "turkish",
        locale: "tr",
        name: "Turkish",
        icon: "tr",
    },
];
