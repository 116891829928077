import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DetailPopover from "@/components/DetailPopover";
import IdViewer from "@/components/IdViewer";
import { MoreOutlined } from "@ant-design/icons";
import { Button } from "@atom/ui-kit";
import { Dropdown, Menu, Tag } from "antd";
export var columns = function (handleEdit, handleDelete, t) { return [
    { title: t("tenantManagement.columns.id"), dataIndex: "id", key: "id", width: 120, render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("tenantManagement.columns.name"), dataIndex: "name", key: "name", width: 120 },
    { title: t("tenantManagement.columns.baseUrl"), dataIndex: "baseUrl", key: "baseUrl", width: 120 },
    { title: t("tenantManagement.columns.whitelistedIPs"), dataIndex: "whitelistedIPs", key: "whitelistedIPs", width: 120, render: function (players) { return _jsx(DetailPopover, { data: players }); } },
    { title: t("tenantManagement.columns.enabledGameTypes"), dataIndex: "enabledGameTypes", key: "enabledGameTypes", width: 120, render: function (text) { return _jsx(Tag, { color: "orange", children: text === null || text === void 0 ? void 0 : text.toString() }); } },
    { title: t("tenantManagement.columns.hmacSecret"), dataIndex: "hmacSecret", key: "hmacSecret", width: 120 },
    { title: t("tenantManagement.columns.contactEmail"), dataIndex: "contactEmail", key: "contactEmail", width: 120 },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 80,
        fixed: "right",
        render: function (_, record) {
            var menu = (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: function () { return handleEdit(record.name); }, children: t("root.edit_button") }, "edit"), _jsx(Menu.Item, { onClick: function () { return handleDelete(record.name); }, children: t("root.delete_button") }, "delete")] }));
            return (_jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(MoreOutlined, {}), children: t("root.actions_button") }) }));
        },
    },
]; };
