/* tslint:disable */
/* eslint-disable */
/**
 * PokerWise Table Management
 * API for managing PokerWise tables.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * TableControllerApi - axios parameter creator
 * @export
 */
export var TableControllerApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Delete a table.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdDelete: function (tenant_1, id_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, id_1], args_1, true), void 0, function (tenant, id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('tablesByidTenantIdDelete', 'tenant', tenant);
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('tablesByidTenantIdDelete', 'id', id);
                            localVarPath = "/tables/byid/{tenant}/{id}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a specific table by ID.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdGet: function (tenant_1, id_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, id_1], args_1, true), void 0, function (tenant, id, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('tablesByidTenantIdGet', 'tenant', tenant);
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('tablesByidTenantIdGet', 'id', id);
                            localVarPath = "/tables/byid/{tenant}/{id}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing table.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the table.
         * @param {TablesByidTenantIdPutRequest} tablesByidTenantIdPutRequest A JSON object for updating the poker table information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdPut: function (tenant_1, id_1, tablesByidTenantIdPutRequest_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, id_1, tablesByidTenantIdPutRequest_1], args_1, true), void 0, function (tenant, id, tablesByidTenantIdPutRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('tablesByidTenantIdPut', 'tenant', tenant);
                            // verify required parameter 'id' is not null or undefined
                            assertParamExists('tablesByidTenantIdPut', 'id', id);
                            // verify required parameter 'tablesByidTenantIdPutRequest' is not null or undefined
                            assertParamExists('tablesByidTenantIdPut', 'tablesByidTenantIdPutRequest', tablesByidTenantIdPutRequest);
                            localVarPath = "/tables/byid/{tenant}/{id}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(tablesByidTenantIdPutRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a table by name.
         * @param {string} tenant The tenant identifier.
         * @param {string} name The name of the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesBynameTenantNameGet: function (tenant_1, name_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, name_1], args_1, true), void 0, function (tenant, name, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('tablesBynameTenantNameGet', 'tenant', tenant);
                            // verify required parameter 'name' is not null or undefined
                            assertParamExists('tablesBynameTenantNameGet', 'name', name);
                            localVarPath = "/tables/byname/{tenant}/{name}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("name", "}"), encodeURIComponent(String(name)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of tables by type with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {string} type The type of the table.
         * @param {number} [limit] The maximum number of tables to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesBytypeTenantTypeGet: function (tenant_1, type_1, limit_1, lastKey_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, type_1, limit_1, lastKey_1], args_1, true), void 0, function (tenant, type, limit, lastKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('tablesBytypeTenantTypeGet', 'tenant', tenant);
                            // verify required parameter 'type' is not null or undefined
                            assertParamExists('tablesBytypeTenantTypeGet', 'type', type);
                            localVarPath = "/tables/bytype/{tenant}/{type}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)))
                                .replace("{".concat("type", "}"), encodeURIComponent(String(type)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (lastKey !== undefined) {
                                localVarQueryParameter['lastKey'] = lastKey;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all tables with pagination.
         * @param {number} [limit] The maximum number of tables to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesGet: function (limit_1, lastKey_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([limit_1, lastKey_1], args_1, true), void 0, function (limit, lastKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/tables";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (lastKey !== undefined) {
                                localVarQueryParameter['lastKey'] = lastKey;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a new table.
         * @param {TablesPostRequest} tablesPostRequest A JSON object containing the poker table information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesPost: function (tablesPostRequest_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tablesPostRequest_1], args_1, true), void 0, function (tablesPostRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tablesPostRequest' is not null or undefined
                            assertParamExists('tablesPost', 'tablesPostRequest', tablesPostRequest);
                            localVarPath = "/tables";
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = serializeDataIfNeeded(tablesPostRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all tables of tenants with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {number} [limit] The maximum number of tables to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesTenantGet: function (tenant_1, limit_1, lastKey_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([tenant_1, limit_1, lastKey_1], args_1, true), void 0, function (tenant, limit, lastKey, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tenant' is not null or undefined
                            assertParamExists('tablesTenantGet', 'tenant', tenant);
                            localVarPath = "/tables/{tenant}"
                                .replace("{".concat("tenant", "}"), encodeURIComponent(String(tenant)));
                            localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication poker-wise-default-authorizer required
                            return [4 /*yield*/, setApiKeyToObject(localVarHeaderParameter, "Cookie", configuration)];
                        case 1:
                            // authentication poker-wise-default-authorizer required
                            _a.sent();
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            if (lastKey !== undefined) {
                                localVarQueryParameter['lastKey'] = lastKey;
                            }
                            setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * TableControllerApi - functional programming interface
 * @export
 */
export var TableControllerApiFp = function (configuration) {
    var localVarAxiosParamCreator = TableControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Delete a table.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdDelete: function (tenant, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesByidTenantIdDelete(tenant, id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesByidTenantIdDelete']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a specific table by ID.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdGet: function (tenant, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesByidTenantIdGet(tenant, id, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesByidTenantIdGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing table.
         * @param {string} tenant The tenant identifier.
         * @param {string} id The unique identifier of the table.
         * @param {TablesByidTenantIdPutRequest} tablesByidTenantIdPutRequest A JSON object for updating the poker table information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdPut: function (tenant, id, tablesByidTenantIdPutRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesByidTenantIdPut(tenant, id, tablesByidTenantIdPutRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesByidTenantIdPut']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of a table by name.
         * @param {string} tenant The tenant identifier.
         * @param {string} name The name of the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesBynameTenantNameGet: function (tenant, name, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesBynameTenantNameGet(tenant, name, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesBynameTenantNameGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get details of tables by type with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {string} type The type of the table.
         * @param {number} [limit] The maximum number of tables to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesBytypeTenantTypeGet: function (tenant, type, limit, lastKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesBytypeTenantTypeGet(tenant, type, limit, lastKey, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesBytypeTenantTypeGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all tables with pagination.
         * @param {number} [limit] The maximum number of tables to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesGet: function (limit, lastKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesGet(limit, lastKey, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a new table.
         * @param {TablesPostRequest} tablesPostRequest A JSON object containing the poker table information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesPost: function (tablesPostRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesPost(tablesPostRequest, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesPost']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get a list of all tables of tenants with pagination.
         * @param {string} tenant The tenant identifier.
         * @param {number} [limit] The maximum number of tables to return.
         * @param {string} [lastKey] The pagination token to continue fetching results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesTenantGet: function (tenant, limit, lastKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs, localVarOperationServerIndex, localVarOperationServerBasePath;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tablesTenantGet(tenant, limit, lastKey, options)];
                        case 1:
                            localVarAxiosArgs = _d.sent();
                            localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                            localVarOperationServerBasePath = (_c = (_b = operationServerMap['TableControllerApi.tablesTenantGet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                            return [2 /*return*/, function (axios, basePath) { return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath); }];
                    }
                });
            });
        },
    };
};
/**
 * TableControllerApi - factory interface
 * @export
 */
export var TableControllerApiFactory = function (configuration, basePath, axios) {
    var localVarFp = TableControllerApiFp(configuration);
    return {
        /**
         *
         * @summary Delete a table.
         * @param {TableControllerApiTablesByidTenantIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdDelete: function (requestParameters, options) {
            return localVarFp.tablesByidTenantIdDelete(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get details of a specific table by ID.
         * @param {TableControllerApiTablesByidTenantIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdGet: function (requestParameters, options) {
            return localVarFp.tablesByidTenantIdGet(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update an existing table.
         * @param {TableControllerApiTablesByidTenantIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesByidTenantIdPut: function (requestParameters, options) {
            return localVarFp.tablesByidTenantIdPut(requestParameters.tenant, requestParameters.id, requestParameters.tablesByidTenantIdPutRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get details of a table by name.
         * @param {TableControllerApiTablesBynameTenantNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesBynameTenantNameGet: function (requestParameters, options) {
            return localVarFp.tablesBynameTenantNameGet(requestParameters.tenant, requestParameters.name, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get details of tables by type with pagination.
         * @param {TableControllerApiTablesBytypeTenantTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesBytypeTenantTypeGet: function (requestParameters, options) {
            return localVarFp.tablesBytypeTenantTypeGet(requestParameters.tenant, requestParameters.type, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get a list of all tables with pagination.
         * @param {TableControllerApiTablesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesGet: function (requestParameters, options) {
            if (requestParameters === void 0) { requestParameters = {}; }
            return localVarFp.tablesGet(requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create a new table.
         * @param {TableControllerApiTablesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesPost: function (requestParameters, options) {
            return localVarFp.tablesPost(requestParameters.tablesPostRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get a list of all tables of tenants with pagination.
         * @param {TableControllerApiTablesTenantGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tablesTenantGet: function (requestParameters, options) {
            return localVarFp.tablesTenantGet(requestParameters.tenant, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * TableControllerApi - object-oriented interface
 * @export
 * @class TableControllerApi
 * @extends {BaseAPI}
 */
var TableControllerApi = /** @class */ (function (_super) {
    __extends(TableControllerApi, _super);
    function TableControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete a table.
     * @param {TableControllerApiTablesByidTenantIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesByidTenantIdDelete = function (requestParameters, options) {
        var _this = this;
        return TableControllerApiFp(this.configuration).tablesByidTenantIdDelete(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get details of a specific table by ID.
     * @param {TableControllerApiTablesByidTenantIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesByidTenantIdGet = function (requestParameters, options) {
        var _this = this;
        return TableControllerApiFp(this.configuration).tablesByidTenantIdGet(requestParameters.tenant, requestParameters.id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update an existing table.
     * @param {TableControllerApiTablesByidTenantIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesByidTenantIdPut = function (requestParameters, options) {
        var _this = this;
        return TableControllerApiFp(this.configuration).tablesByidTenantIdPut(requestParameters.tenant, requestParameters.id, requestParameters.tablesByidTenantIdPutRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get details of a table by name.
     * @param {TableControllerApiTablesBynameTenantNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesBynameTenantNameGet = function (requestParameters, options) {
        var _this = this;
        return TableControllerApiFp(this.configuration).tablesBynameTenantNameGet(requestParameters.tenant, requestParameters.name, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get details of tables by type with pagination.
     * @param {TableControllerApiTablesBytypeTenantTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesBytypeTenantTypeGet = function (requestParameters, options) {
        var _this = this;
        return TableControllerApiFp(this.configuration).tablesBytypeTenantTypeGet(requestParameters.tenant, requestParameters.type, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get a list of all tables with pagination.
     * @param {TableControllerApiTablesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesGet = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return TableControllerApiFp(this.configuration).tablesGet(requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create a new table.
     * @param {TableControllerApiTablesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesPost = function (requestParameters, options) {
        var _this = this;
        return TableControllerApiFp(this.configuration).tablesPost(requestParameters.tablesPostRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get a list of all tables of tenants with pagination.
     * @param {TableControllerApiTablesTenantGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableControllerApi
     */
    TableControllerApi.prototype.tablesTenantGet = function (requestParameters, options) {
        var _this = this;
        return TableControllerApiFp(this.configuration).tablesTenantGet(requestParameters.tenant, requestParameters.limit, requestParameters.lastKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TableControllerApi;
}(BaseAPI));
export { TableControllerApi };
