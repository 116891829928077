import debounce from "lodash/debounce";
import { useLayoutEffect, useState } from "react";
export var useTableHeight = function (ref) {
    // Keep the Table the height of the parent.
    var _a = useState(), tableHeight = _a[0], setTableHeight = _a[1];
    var resizeTable = debounce(function () {
        var node = ref.current;
        if (!node) {
            return;
        }
        var height = node.getBoundingClientRect().height;
        // height of the content minus the header and footer
        setTableHeight(height - 55 - 48);
    }, 100, {
        trailing: true,
        maxWait: 100,
    });
    useLayoutEffect(function () {
        resizeTable();
        window.addEventListener("resize", resizeTable);
        return function () {
            window.removeEventListener("resize", resizeTable);
        };
    }, [resizeTable]);
    return tableHeight;
};
