export const userManagement = {
  userManagement: {
    edit: {
      title: "Kullanıcıyı Düzenle",
      username: "Kullanıcı Adı",
      username_required: "Lütfen kullanıcı adı girin",
      email: "E-posta",
      email_required: "Lütfen e-posta girin",
      role: "Rol",
      role_required: "Lütfen rol girin",
      password: "Şifre",
      password_required: "Lütfen şifre girin",
      organization: "Organizasyon",
      organization_required: "Lütfen organizasyon girin",
      tenant: "Tenant",
      tenant_required: "Lütfen tenant girin",
      lobby: "Lobi",
      lobby_required: "Lütfen lobi girin",
      passwordExpirationDate: "Şifre Geçerlilik Tarihi",
      is_first_password: "İlk Şifre mi",
      is_raw_password: "Ham Şifre mi",
      whitelistedIPs: "Beyaz Listeye Alınmış IP'ler",
      locale: "Yerel Ayar",
      language: "Dil",
      telephone: "Telefon",
      note: "Not",
      createdBy: "Oluşturan",
    },
    add: {
      title: "Kullanıcı Ekle",
      username: "Kullanıcı Adı",
      username_placeholder: "Kullanıcı Adı Girin",
      username_required: "Lütfen kullanıcı adı girin",
      email: "E-posta",
      email_placeholder: "E-posta Girin",
      email_required: "Lütfen e-posta girin",
      role: "Rol",
      role_placeholder: "Rol Girin",
      role_required: "Lütfen rol girin",
      password: "Şifre",
      password_placeholder: "Şifre Girin",
      password_required: "Lütfen şifre girin",
      organization: "Organizasyon",
      organization_placeholder: "Organizasyon Girin",
      organization_required: "Lütfen organizasyon girin",
      tenant: "Tenant",
      tenant_placeholder: "Tenant Girin",
      tenant_required: "Lütfen tenant girin",
      lobby: "Lobi",
      lobby_placeholder: "Lobi Girin",
      lobby_required: "Lütfen lobi girin",
      passwordExpirationDate: "Şifre Geçerlilik Tarihi",
      passwordExpirationDate_placeholder: "Şifre Geçerlilik Tarihini Girin",
      is_first_password: "İlk Şifre mi",
      is_first_password_placeholder: "İlk Şifre mi Girin",
      is_raw_password: "Ham Şifre mi",
      is_raw_password_placeholder: "Ham Şifre mi Girin",
      whitelistedIPs: "Beyaz Listeye Alınmış IP'ler",
      whitelistedIPs_placeholder: "Beyaz Listeye Alınmış IP'leri Girin",
      locale: "Yerel Ayar",
      locale_placeholder: "Yerel Ayarı Girin",
      language: "Dil",
      language_placeholder: "Dil Girin",
      telephone: "Telefon",
      telephone_placeholder: "Telefon Girin",
      note: "Not",
      note_placeholder: "Not Girin",
      createdBy: "Oluşturan",
      createdBy_placeholder: "Oluşturanı Girin",
      success: "Kullanıcı başarıyla eklendi",
      error: "Kullanıcı eklenirken hata oluştu",
    },
    columns: {
      id: "Kimlik Görüntüleyici",
      tenant: "Tenant",
      username: "Kullanıcı Adı",
      email: "E-posta",
      role: "Rol",
      createdAt: "Oluşturulma Tarihi",
      updatedAt: "Güncellenme Tarihi",
    },
    filters: {
      userName_placeholder: "Kullanıcı Adı",
      userId_placeholder: "Kullanıcı Kimliği",
    },
  },
};
