import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IdView from "@/components/IdViewer";
import { EyeOutlined } from "@ant-design/icons";
import { Button } from "@atom/ui-kit";
export var columns = function (handleOpenDetail, t, lobbyData) { return [
    { title: t("tableManagement.columns.lobby"), dataIndex: "lobby_id", key: "lobby_id", width: 80, render: function (name) { var _a, _b; return ((_b = (_a = lobbyData === null || lobbyData === void 0 ? void 0 : lobbyData.items) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === name; })) === null || _b === void 0 ? void 0 : _b.name) || _jsx(IdView, { id: name }); } },
    { title: t("tableManagement.columns.name"), dataIndex: "name", key: "name", width: 80, render: function (name) { return _jsx(IdView, { id: name }); } },
    { title: t("tableManagement.columns.id"), dataIndex: "id", key: "id", width: 80, render: function (id) { return _jsx(IdView, { id: id }); } },
    { title: t("tableManagement.columns.type"), dataIndex: "type", key: "type", width: 80 },
    {
        title: t("tableManagement.columns.blinds"),
        dataIndex: "blinds",
        key: "blinds",
        width: 80,
        render: function (blinds, record) { return (_jsxs("span", { children: [blinds.small, record.currency, "/", blinds.big, record.currency] })); },
    },
    {
        title: t("tableManagement.columns.buyInRange"),
        dataIndex: "minBuyIn",
        key: "buyInRange",
        width: 80,
        render: function (_, record) { return (_jsxs("div", { children: [record.minBuyIn, " ", record.currency, " - ", record.maxBuyIn, " ", record.currency] })); },
    },
    {
        title: t("tableManagement.columns.players"),
        dataIndex: "totalSeats",
        key: "totalSeats",
        width: 80,
        render: function (_, record) { return (_jsxs("span", { children: [record.currentAllocatedSeats, " / ", record.totalSeats] })); },
    },
    {
        title: t("tableManagement.columns.action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 100,
        render: function (_, record) {
            return (_jsx(Button, { buttonType: "primary", buttonSize: "small", icon: _jsx(EyeOutlined, {}), onClick: function () { return handleOpenDetail([record]); }, children: t("root.view_button") }));
        },
    },
]; };
