import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb as AntdBreadCrumb } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./BreadCrumb.style";
var BreadCrumb = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var pathSnippets = location.pathname.split("/").filter(function (i) { return i; });
    var t = useTranslation().t;
    var breadcrumbItems = pathSnippets.map(function (_, index) {
        var url = "/".concat(pathSnippets.slice(0, index + 1).join("/"));
        var isLastItem = index === pathSnippets.length - 1;
        return (_jsx(AntdBreadCrumb.Item, { children: !isLastItem ? (_jsx("span", { onClick: function () { return navigate(url); }, style: { cursor: "pointer", color: "#1890ff" }, children: t("sidebar.menu." + pathSnippets[index]).toUpperCase() })) : (pathSnippets[index].replace(/-/g, " ").toUpperCase()) }, url));
    });
    return (_jsx(Container, { children: _jsxs(AntdBreadCrumb, { children: [_jsx(AntdBreadCrumb.Item, { onClick: function () { return navigate("/"); }, children: _jsx(HomeOutlined, {}) }), breadcrumbItems] }) }));
};
export default BreadCrumb;
