import { gameManagement } from "./pages/game-management";
import { lobbyManagement } from "./pages/lobby-management";
import { playerManagement } from "./pages/player-management";
import { signIn } from "./pages/sign-in";
import { tableManagement } from "./pages/table-management";
import { tenantManagement } from "./pages/tenant-management";
import { userManagement } from "./pages/user-management";

export default {
  root: {
    delete_confirmation_title: "Bu Öğeyi Sil?",
    delete_confirmation_message: "Bu öğeyi silmek istediğinizden emin misiniz?",
    delete_success: "Öğe başarıyla silindi",
    delete_error: "Öğe silinirken hata oluştu",
    confirm: "Onayla",
    cancel: "İptal",
    edit_button: "Veriyi Düzenle",
    delete_button: "Veriyi Sil",
    save_button: "Kaydet",
    refresh_button: "Yenile",
    add_button: "Öğe Ekle",
    actions_button: "Eylemler",
    view_button: "Detay Görüntüle",
    details: "Detaylar",
  },
  sidebar: {
    logout: "Çıkış Yap",
    search_input_placeholder: "Ara...",
    menu: {
      dashboard: "Gösterge Paneli",
      "table-management": "Masa Listesi",
      "game-management": "Oyun Geçmişi",
      "lobby-management": "Lobi Listesi",
      "user-management": "Admin Listesi",
      "tenant-management": "Tenant Listesi",
      "operator-management": "Operatör Listesi",
      "player-management": "Oyuncu Listesi",
    },
  },
  dashboard: {
    title: "Gösterge Paneline Hoş Geldiniz {{name}}",
    description: "Bu gösterge paneli sayfasıdır. Sol taraftan bir menü seçebilirsiniz.",
  },
  ...lobbyManagement,
  ...tableManagement,
  ...gameManagement,
  ...tenantManagement,
  ...userManagement,
  ...playerManagement,
  ...signIn,
};
